@media only screen and (max-width: 767px) {
  body {
    font-size: 14px;
  }
  p {
    line-height: 1.8;
    font-size: 14px;
  }
  .ptb-70 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .section-title {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 40px;
  }
  .section-title h2 {
    font-size: 26px;
  }
  .section-title .bar {
    height: 4px;
  }
  .section-title p {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .btn {
    padding: 14px 25px;
    font-size: 13px;
  }
  .main-banner {
    height: 100%;
    padding-top: 280px;
    padding-bottom: 220px;

    background-image: url(../img/Cropped.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .main-banner-content {
    margin-top: 0;
    max-width: 100%;
    text-align: center;
  }
  .main-banner-content h1 {
    font-size: 35px;
    line-height: 1.3;
    display: none;
  }
  .main-banner-content p {
    font-size: 15px;
    max-width: 100%;
    /* margin-top: 15px; */
    margin-top: 200px;
    line-height: 1.6;
  }
  .main-banner-content .btn {
    margin-top: 25px;
  }
  .main-banner-section {
    height: 100%;
    padding-top: 160px;
    padding-bottom: 230px;
  }
  .banner-content {
    margin-top: 0;
    text-align: center;
  }
  .banner-content h1 {
    font-size: 35px;
    line-height: 1.3;
  }
  .banner-content p {
    font-size: 15px;
    max-width: 100%;
    margin-top: 15px;
    line-height: 1.6;
  }
  .banner-content .btn {
    margin-top: 25px;
  }
  .money-transfer-form {
    padding: 30px 20px;
    margin-top: 60px;
    margin-left: 0;
  }
  .money-transfer-form form .currency-info {
    margin-bottom: 20px;
    margin-top: 20px;
    padding-left: 15px;
  }
  .money-transfer-form form .currency-info span {
    display: block;
  }
  .money-transfer-form form .currency-info span:last-child {
    margin-left: 0;
    margin-top: 6px;
  }
  .banner-section {
    padding-top: 145px;
  }
  .hero-content {
    margin-top: 0;
    text-align: center;
  }
  .hero-content h1 {
    font-size: 35px;
    line-height: 1.3;
  }
  .hero-content p {
    font-size: 15px;
    max-width: 100%;
    margin-top: 15px;
    line-height: 1.6;
  }
  .hero-content .btn {
    margin-top: 25px;
  }
  .hero-image {
    margin-top: 10px;
  }
  .hero-image .main-image {
    display: none;
  }
  .hero-image .main-mobile-image {
    display: block;
  }
  .hero-image .circle-image {
    display: none;
  }
  .about-content {
    padding-right: 0;
  }
  .about-content span {
    font-size: 11px;
  }
  .about-content h2 {
    font-size: 26px;
  }
  .about-image {
    margin-top: 30px;
  }
  .featured-boxes-inner {
    -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.03);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.03);
  }
  .featured-boxes-inner .col-lg-3 {
    border-right: none;
    border-bottom: 1px solid #eee;
  }
  .featured-boxes-inner .col-lg-3:last-child {
    border-bottom: none;
  }
  .single-featured-box {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 100px;
    padding-bottom: 36px;
  }
  .single-featured-box .icon {
    top: 25px;
    -webkit-animation: iconTop 0.4s ease-in-out;
    animation: iconTop 0.4s ease-in-out;
  }
  .single-featured-box .icon i::before {
    font-size: 40px;
  }
  .single-featured-box h3 {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
    font-size: 19px;
  }
  .single-featured-box p {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  .single-featured-box .read-more-btn {
    opacity: 1;
    visibility: visible;
    bottom: 20px;
  }
  .single-featured-box:hover .icon {
    top: 25px;
  }
  .single-featured-box:hover .read-more-btn {
    bottom: 20px;
  }
  .services-area .container-fluid {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .overview-box {
    margin-left: -15px;
    margin-right: -15px;
  }
  .overview-box .overview-content {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    padding-left: 15px;
    padding-right: 15px;
  }
  .overview-box .overview-content .content {
    padding-left: 0;
    max-width: 100%;
    text-align: center;
  }
  .overview-box .overview-content .content.left-content {
    margin-left: 0;
    padding-right: 0;
  }
  .overview-box .overview-content .content h2 {
    font-size: 26px;
    line-height: 1.3;
  }
  .overview-box .overview-content .content .bar {
    height: 4px;
    margin: 20px auto;
  }
  .overview-box .overview-content .content .services-list {
    text-align: left;
  }
  .overview-box .overview-content .content .services-list li {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .overview-box .overview-content .content .services-list li span {
    font-size: 13.5px;
  }
  .overview-box .overview-content .content .features-list {
    margin-top: -10px;
    text-align: left;
  }
  .overview-box .overview-content .content .features-list li {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .overview-box .overview-content .content .sub-title {
    margin-bottom: 9px;
    font-size: 11.5px;
  }
  .overview-box .overview-content .content .btn {
    margin-top: 25px;
  }
  .overview-box .overview-image {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    margin-top: 40px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .comparisons-table table thead th {
    padding: 13px 20px;
    white-space: nowrap;
    font-size: 17px;
    font-weight: 500;
  }
  .comparisons-table table thead th:nth-child(1) {
    padding-left: 20px;
  }
  .comparisons-table table tbody td {
    white-space: nowrap;
    padding: 13px 20px;
    font-size: 14px;
  }
  .comparisons-table table tbody td:nth-child(1) {
    padding-left: 20px;
  }
  .features-area {
    padding-bottom: 20px;
  }
  .single-features-box {
    text-align: center;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-right: 20px;
    padding-left: 20px;
  }
  .single-features-box .icon {
    width: 60px;
    height: 60px;
    line-height: 61px;
    margin-left: auto;
    margin-right: auto;
  }
  .single-features-box .icon i::before {
    font-size: 23px;
  }
  .single-features-box h3 {
    font-size: 19px;
    line-height: 1.4;
  }
  .features-section .container-fluid {
    max-width: 540px;
  }
  .features-box-list {
    margin-left: 0;
    max-width: 100%;
  }
  .features-box {
    text-align: center;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-right: 20px;
    padding-left: 20px;
  }
  .features-box .icon {
    position: relative;
    width: 60px;
    height: 60px;
    line-height: 61px;
    left: 0;
    top: 0;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
  }
  .features-box .icon i::before {
    font-size: 23px;
  }
  .features-box h3 {
    font-size: 19px;
  }
  .features-image {
    margin-top: 30px;
  }
  .team-area {
    padding-bottom: 20px;
  }
  .single-team-member {
    text-align: center;
  }
  .single-team-member .member-image {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .single-team-member .member-content {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 20px;
  }
  .single-team-member .member-content h3 {
    font-size: 19px;
  }
  .single-team-member .member-content span {
    font-size: 12px;
  }
  .single-team-member .member-content p {
    font-size: 13.3px;
  }
  .invoicing-content {
    max-width: 100%;
    margin-left: 0;
    text-align: center;
  }
  .invoicing-content h2 {
    font-size: 26px;
    line-height: 1.4;
  }
  .invoicing-content .bar {
    height: 4px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }
  .invoicing-image {
    margin-top: 30px;
  }
  .invoicing-image .main-image {
    display: none;
  }
  .invoicing-image .main-mobile-image {
    display: block;
  }
  .invoicing-image .circle-image {
    display: none;
  }
  .information-area {
    padding-top: 0;
  }
  .single-information-box {
    margin-top: 20px;
    padding-left: 0;
    text-align: center;
  }
  .single-information-box .icon {
    position: relative;
    top: 0;
    margin-bottom: 15px;
    margin-left: auto;
    margin-right: auto;
  }
  .single-information-box p {
    margin-top: 7px;
  }
  .single-information-box .btn-box .app-store-btn {
    padding: 12px 15px 12px 44px;
    font-size: 11px;
  }
  .single-information-box .btn-box .app-store-btn i {
    left: 10px;
  }
  .single-information-box .btn-box .app-store-btn i::before {
    font-size: 22px;
  }
  .single-information-box .btn-box .app-store-btn span {
    font-size: 15.5px;
  }
  .single-information-box .btn-box .play-store-btn {
    margin-left: 10px;
    padding: 12px 15px 12px 44px;
    font-size: 11px;
  }
  .single-information-box .btn-box .play-store-btn i {
    left: 10px;
  }
  .single-information-box .btn-box .play-store-btn i::before {
    font-size: 22px;
  }
  .single-information-box .btn-box .play-store-btn span {
    font-size: 15.5px;
  }
  .funfacts-area .row {
    padding-right: 0;
    padding-left: 0;
  }
  .funfact {
    margin-bottom: 30px;
  }
  .funfact h3 {
    font-size: 30px;
  }
  .contact-cta-box {
    text-align: center;
    margin: 20px 0 0;
    max-width: 100%;
    padding: 25px;
  }
  .contact-cta-box h3 {
    margin-bottom: 10px;
    font-size: 19px;
  }
  .contact-cta-box .btn {
    position: relative;
    right: 0;
    top: 0;
    -webkit-transform: unset;
    transform: unset;
    margin-top: 15px;
  }
  .pricing-area {
    padding-bottom: 20px;
  }
  .single-pricing-box .pricing-header h3 {
    font-size: 19px;
  }
  .single-pricing-box .pricing-header p {
    font-size: 13px;
    margin-top: 5px;
  }
  .single-pricing-box .price {
    font-size: 38px;
  }
  .single-pricing-box .price span {
    margin-left: -7px;
    font-size: 15px;
  }
  .how-it-works-area {
    padding-bottom: 20px;
  }
  .single-how-it-works {
    padding: 30px 20px;
  }
  .single-how-it-works h3 {
    font-size: 19px;
  }
  .single-how-it-works p {
    font-size: 13.5px;
  }
  .feedback-slides .client-feedback .single-feedback {
    text-align: center;
    margin-bottom: 40px;
    padding: 20px;
  }
  .feedback-slides .client-feedback .single-feedback .client-img {
    position: relative;
    left: 0;
    top: 0;
    margin-bottom: 15px;
  }
  .feedback-slides .client-feedback .single-feedback .client-img img {
    display: inline-block;
  }
  .feedback-slides .client-feedback .single-feedback h3 {
    font-size: 19px;
  }
  .feedback-slides .client-feedback .single-feedback span {
    font-size: 13px;
  }
  .feedback-slides .client-feedback .single-feedback::before {
    bottom: -15px;
    width: 30px;
    height: 30px;
  }
  .feedback-slides .client-thumbnails {
    margin: 0 20px;
  }
  .feedback-slides .client-thumbnails .item .img-fill img {
    border-width: 1px;
    padding: 0;
  }
  .ready-to-talk {
    padding-top: 50px;
  }
  .ready-to-talk-content h3 {
    font-size: 26px;
  }
  .ready-to-talk-content span {
    display: block;
    margin-top: 10px;
  }
  .ready-to-talk-content span a {
    margin-left: 0;
  }
  .partner-area h3 {
    font-size: 19px;
    line-height: 1.4;
  }
  .partner-inner {
    margin-top: 15px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .app-image {
    overflow: hidden;
  }
  .app-image .main-image {
    display: none;
  }
  .app-image .main-mobile-image {
    display: block;
  }
  .app-download-content {
    text-align: center;
    padding-left: 0;
    margin-top: 20px;
  }
  .app-download-content h2 {
    font-size: 26px;
    line-height: 1.4;
  }
  .app-download-content .bar {
    height: 4px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }
  .app-download-content .btn-box .app-store-btn {
    padding: 12px 15px 12px 44px;
    font-size: 11px;
  }
  .app-download-content .btn-box .app-store-btn i {
    left: 10px;
  }
  .app-download-content .btn-box .app-store-btn i::before {
    font-size: 22px;
  }
  .app-download-content .btn-box .app-store-btn span {
    font-size: 15.5px;
  }
  .app-download-content .btn-box .play-store-btn {
    margin-left: 10px;
    padding: 12px 15px 12px 44px;
    font-size: 11px;
  }
  .app-download-content .btn-box .play-store-btn i {
    left: 10px;
  }
  .app-download-content .btn-box .play-store-btn i::before {
    font-size: 22px;
  }
  .app-download-content .btn-box .play-store-btn span {
    font-size: 15.5px;
  }
  .app-download-area {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .account-create-area {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .account-create-content h2 {
    max-width: 100%;
    font-size: 25px;
    line-height: 1.4;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
  }
  .account-create-content p {
    font-size: 15px;
    margin-top: 15px;
  }
  .account-create-content .btn-primary {
    margin-top: 25px;
  }
  .blog-area {
    padding-bottom: 20px;
  }
  .blog-area .widget-area {
    margin-top: 10px;
    margin-bottom: 30px;
  }
  .single-blog-post .blog-post-content h3 {
    line-height: 28px;
    font-size: 19px;
    font-weight: 400;
  }
  .blog-details .article-content .entry-meta {
    margin-bottom: 12px;
  }
  .blog-details .article-content .entry-meta ul li {
    display: block;
    margin-right: 0;
    margin-bottom: 4px;
  }
  .blog-details .article-content .entry-meta ul li::before {
    display: none;
  }
  .blog-details .article-content .entry-meta ul li:last-child {
    margin-bottom: 0;
  }
  .blog-details .article-content h3 {
    font-size: 19px;
    line-height: 1.4;
  }
  .blog-details .article-content p {
    font-size: 14px;
  }
  blockquote,
  .blockquote {
    padding: 30px !important;
  }
  blockquote p,
  .blockquote p {
    font-size: 16px !important;
  }
  .comments-area .comments-title {
    font-size: 19px;
  }
  .comments-area .comment-content p {
    font-size: 13px;
  }
  .comments-area .comment-respond .comment-reply-title {
    font-size: 19px;
  }
  .comments-area .comment-respond .comment-notes {
    font-size: 13px;
  }
  .comments-area .comment-respond .comment-form-author {
    width: 100%;
    padding-right: 0;
  }
  .comments-area .comment-respond .comment-form-email {
    width: 100%;
    padding-left: 0;
  }
  .comments-area .comment-respond .form-submit input {
    font-size: 13px;
  }
  .page-title-area {
    padding-top: 160px;
    padding-bottom: 100px;
  }
  .page-title-content h2 {
    font-size: 27px;
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .page-title-content p {
    font-size: 13px;
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .page-title-section {
    background-position: center center;
    padding-top: 160px;
    padding-bottom: 100px;
  }
  .page-title-text {
    text-align: center;
  }
  .page-title-text h2 {
    font-size: 27px;
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .page-title-text p {
    font-size: 13px;
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .page-title-text ul {
    position: relative;
    top: 0;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    margin-top: 10px;
  }
  .page-title-text ul li {
    font-size: 13px;
  }
  .page-title-text ul li::before {
    top: 2.5px;
  }
  .error-area {
    height: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .error-content h3 {
    font-size: 25px;
    margin-top: 40px;
    margin-bottom: 10px;
  }
  .faq-content h2 {
    font-size: 26px;
  }
  .faq-content .bar {
    height: 4px;
    margin: 20px 0;
  }
  .faq-accordion {
    margin-top: 30px;
  }
  .faq-contact {
    margin-top: 50px;
  }
  .widget-area {
    margin-top: 40px;
  }
  .widget-area .widget .widget-title {
    font-size: 19px;
  }
  .widget-area .widget_luvion_posts_thumb .item .info .title {
    font-size: 14px;
  }
  .widget-area .widget_recent_entries ul li {
    font-size: 13.5px;
  }
  .widget-area .widget_recent_comments ul li {
    font-size: 13.5px;
  }
  .widget-area .widget_archive ul li {
    font-size: 13.5px;
  }
  .widget-area .widget_categories ul li {
    font-size: 13.5px;
  }
  .widget-area .widget_meta ul li {
    font-size: 13.5px;
  }
  .contact-info ul li {
    padding-left: 87px;
  }
  .contact-info ul li span {
    font-size: 19px;
  }
  .contact-info ul li .icon {
    width: 70px;
    height: 70px;
    line-height: 70px;
    font-size: 20px;
  }
  .contact-form {
    margin-top: 30px;
  }
  .login-area .col-lg-6:first-child {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .login-area .col-lg-6:last-child {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .login-image {
    background-image: unset;
    height: auto;
  }
  .login-image img {
    display: block;
  }
  .login-content {
    height: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .login-content .login-form .logo {
    margin-bottom: 25px;
  }
  .login-content .login-form h3 {
    font-size: 26px;
  }
  .login-content .login-form p {
    font-size: 13px;
  }
  .login-content .login-form form {
    margin-top: 25px;
  }
  .signup-area .col-lg-6:first-child {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .signup-area .col-lg-6:last-child {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .signup-image {
    background-image: unset;
    height: auto;
  }
  .signup-image img {
    display: block;
  }
  .signup-content {
    height: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .signup-content .signup-form .logo {
    margin-bottom: 25px;
  }
  .signup-content .signup-form h3 {
    font-size: 26px;
  }
  .signup-content .signup-form p {
    font-size: 13px;
  }
  .signup-content .signup-form form {
    margin-top: 25px;
  }
  .footer-area {
    padding-top: 50px;
  }
  .single-footer-widget.pl-5 {
    padding-left: 0 !important;
  }
  .single-footer-widget h3 {
    margin-bottom: 20px;
    font-size: 19px;
  }
  .copyright-area {
    margin-top: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .map-image {
    display: none;
  }
  .go-top {
    right: 10px;
    width: 35px;
    height: 35px;
    line-height: 35px;
  }
  .home-area {
    padding-top: 120px;
    padding-bottom: 160px;
  }
  .banner-item-content {
    text-align: center;
  }
  .banner-item-content h1 {
    font-size: 26px;
  }
  .banner-item-content p {
    font-size: 15px;
    max-width: 100%;
    margin-top: 15px;
  }
  .banner-item-content .btn {
    margin-top: 20px;
  }
  .banner-item-image {
    margin-left: 0;
    margin-top: 30px;
  }
  .home-slides.owl-theme .owl-nav {
    top: 0;
    right: 0;
    opacity: 1;
    -webkit-transform: unset;
    transform: unset;
    margin-top: 30px;
    position: relative;
    visibility: visible;
  }
  .home-slides.owl-theme .owl-nav [class*="owl-"] {
    width: 35px;
    height: 35px;
    margin: 0 5px;
    font-size: 15px;
    display: inline-block;
  }
  .banner-wrapper {
    padding-top: 130px;
  }
  .banner-wrapper .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
  .banner-wrapper-content {
    max-width: 100%;
    padding-right: 0;
    margin-top: 0;
    text-align: center;
  }
  .banner-wrapper-content h1 {
    font-size: 26px;
  }
  .banner-wrapper-content p {
    font-size: 15px;
    max-width: 100%;
    margin-top: 15px;
  }
  .banner-wrapper-content .btn {
    margin-top: 25px;
  }
  .banner-wrapper-image {
    text-align: center;
    margin-top: 20px;
  }
  .payment-experience-content {
    margin-bottom: 30px;
    text-align: center;
  }
  .payment-experience-content h2 {
    margin-bottom: 18px;
    font-size: 27px;
  }
  .single-payment-experience-box {
    text-align: center;
  }
  .single-payment-experience-box .icon {
    width: 75px;
    height: 75px;
    line-height: 80px;
    font-size: 30px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }
  .single-payment-experience-box h3 {
    font-size: 19px;
  }
  .support-image {
    padding-right: 0;
    margin-bottom: 30px;
  }
  .support-image img {
    width: 100%;
  }
  .support-image img:nth-child(2) {
    position: relative;
    top: 0;
    margin-top: 15px;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  .support-content {
    padding-left: 0;
    text-align: center;
  }
  .support-content h2 {
    font-size: 27px;
  }
  .business-content {
    text-align: center;
    margin-bottom: 30px;
  }
  .business-content h2 {
    font-size: 27px;
  }
  .business-content .single-business-box h3 {
    font-size: 18px;
  }
  .business-image {
    margin-left: 0;
    padding: 20px;
  }
  .testimonials-area {
    padding-bottom: 20px;
  }
  .single-testimonials-box {
    padding: 25px;
  }
  .single-testimonials-box p {
    font-size: 14px;
  }
  .single-testimonials-box h3 {
    margin-top: 15px;
    font-size: 14px;
  }
  .success-story-inner {
    text-align: center;
    padding-top: 25px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 25px;
  }
  .success-story-inner::before {
    display: none;
  }
  .success-story-inner .owl-theme .owl-nav {
    right: 0;
    top: 0;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    position: relative;
    margin-top: 25px;
  }
  .success-story-inner .owl-theme .owl-nav [class*="owl-"] {
    width: 35px;
    height: 35px;
    display: inline-block;
    line-height: 37px;
    margin: 0 5px;
    font-size: 16px;
  }
  .single-success-story-box .content {
    padding-right: 0;
  }
  .single-success-story-box .content p {
    font-size: 15px;
  }
  .single-success-story-box .content h3 {
    margin-top: 20px;
    font-size: 14px;
  }
  .single-success-story-box .content .btn {
    margin-top: 25px;
  }
  .single-success-story-box .image {
    margin-top: 30px;
  }
  .global-content {
    padding-right: 0;
    margin-bottom: 30px;
    text-align: center;
  }
  .global-content ul li {
    padding: 20px;
    font-size: 15px;
  }
  .payment-features-overview {
    margin-top: 50px;
  }
  .payment-features-overview .payment-features-image {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .payment-features-overview .payment-features-image .image {
    margin-right: 0;
    padding: 20px;
  }
  .payment-features-overview .payment-features-content {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .payment-features-overview .payment-features-content .content {
    margin-top: 25px;
    text-align: center;
    padding-left: 0;
  }
  .payment-features-overview .payment-features-content .content h2 {
    margin-bottom: 15px;
    font-size: 27px;
  }
  .payment-features-overview:nth-child(2) .payment-features-content .content,
  .payment-features-overview:nth-child(4) .payment-features-content .content,
  .payment-features-overview:nth-child(6) .payment-features-content .content {
    padding-left: 0;
  }
  .payment-features-overview:nth-child(2) .payment-features-image .image,
  .payment-features-overview:nth-child(4) .payment-features-image .image,
  .payment-features-overview:nth-child(6) .payment-features-image .image {
    margin-left: 0;
  }
  .get-started-title {
    text-align: center;
  }
  .get-started-title h2 {
    font-size: 27px;
    margin-bottom: 25px;
  }
  .get-started-content {
    text-align: center;
    margin-top: 25px;
  }
  .get-started-content p {
    font-size: 14px;
    font-weight: 500;
  }

  .signup-wrapper .btn {
    width: 45% !important;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .featured-boxes-inner .col-lg-3 {
    border-right: 1px solid #eee;
  }
  .featured-boxes-inner .col-lg-3:nth-child(2),
  .featured-boxes-inner .col-lg-3:nth-child(4),
  .featured-boxes-inner .col-lg-3:nth-child(6) {
    border-right: none;
  }
  .featured-boxes-inner .col-lg-3:nth-child(3) {
    border-bottom: none;
  }
  .services-area .container-fluid {
    max-width: 540px;
  }
  .overview-box .overview-content .content .services-list li {
    -ms-flex: 0 0 50%;
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .overview-box .overview-content .content .features-list li {
    -ms-flex: 0 0 50%;
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .app-download-content .btn-box .app-store-btn {
    padding: 12px 25px 12px 60px;
    font-size: 12px;
  }
  .app-download-content .btn-box .app-store-btn i {
    left: 16px;
  }
  .app-download-content .btn-box .app-store-btn i::before {
    font-size: 35px;
  }
  .app-download-content .btn-box .app-store-btn span {
    font-size: 18px;
  }
  .app-download-content .btn-box .play-store-btn {
    padding: 12px 25px 12px 60px;
    font-size: 12px;
  }
  .app-download-content .btn-box .play-store-btn i {
    left: 16px;
  }
  .app-download-content .btn-box .play-store-btn i::before {
    font-size: 35px;
  }
  .app-download-content .btn-box .play-store-btn span {
    font-size: 18px;
  }
  .single-information-box {
    padding-left: 60px;
    text-align: left;
    margin-top: 30px;
  }
  .single-information-box .icon {
    position: absolute;
    left: 0;
    top: 0;
    margin-bottom: 0;
    margin-right: 0;
    margin-left: 0;
  }
  .single-information-box .btn-box .app-store-btn {
    text-align: left;
    padding: 10px 10px 10px 30px;
    font-size: 10px;
  }
  .single-information-box .btn-box .app-store-btn i {
    left: 5px;
  }
  .single-information-box .btn-box .app-store-btn i::before {
    font-size: 17px;
  }
  .single-information-box .btn-box .app-store-btn span {
    font-size: 15px;
  }
  .single-information-box .btn-box .play-store-btn {
    text-align: left;
    margin-left: 5px;
    padding: 10px 10px 10px 30px;
    font-size: 10px;
  }
  .single-information-box .btn-box .play-store-btn i {
    left: 5px;
  }
  .single-information-box .btn-box .play-store-btn i::before {
    font-size: 17px;
  }
  .single-information-box .btn-box .play-store-btn span {
    font-size: 15px;
  }
  .single-team-member {
    text-align: left;
  }
  .single-team-member .member-image {
    -ms-flex: 0 0 33.333333%;
    -webkit-box-flex: 0;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .single-team-member .member-content {
    -ms-flex: 0 0 66.666667%;
    -webkit-box-flex: 0;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
    margin-top: 0;
  }
  .invoicing-area .container-fluid {
    max-width: 540px;
  }
  .banner-wrapper .container-fluid {
    max-width: 540px;
  }
  .support-image {
    padding-right: 100px;
  }
  .support-image img {
    width: auto;
  }
  .support-image img:nth-child(2) {
    margin-top: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title {
    max-width: 600px;
  }
  .main-banner {
    height: 100%;
    padding-top: 220px;
    padding-bottom: 230px;
  }
  .main-banner-section {
    height: 100%;
    padding-top: 165px;
    padding-bottom: 220px;
  }
  .banner-content {
    margin-top: 0;
    text-align: center;
  }
  .banner-content p {
    margin-left: auto;
    margin-right: auto;
  }
  .money-transfer-form {
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
  }
  .banner-section {
    padding-top: 170px;
    background-position: left top;
  }
  .hero-content {
    margin-top: 0;
    text-align: center;
  }
  .hero-content h1 {
    font-size: 45px;
  }
  .hero-content p {
    margin-left: auto;
    margin-right: auto;
  }
  .hero-image .main-image img:nth-child(3) {
    right: -25px;
    top: 110px;
  }
  .hero-image .circle-image {
    display: none;
  }
  .about-content {
    padding-right: 0;
    text-align: center;
    margin-bottom: 30px;
  }
  .information-area {
    padding-top: 0;
  }
  .single-information-box {
    margin-top: 30px;
  }
  .single-information-box .btn-box .play-store-btn {
    padding: 10px 15px 10px 40px;
    font-size: 13px;
  }
  .single-information-box .btn-box .play-store-btn span {
    font-size: 18px;
  }
  .featured-boxes-inner .col-lg-3 {
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
  }
  .featured-boxes-inner .col-lg-3:nth-child(2),
  .featured-boxes-inner .col-lg-3:nth-child(4),
  .featured-boxes-inner .col-lg-3:nth-child(6) {
    border-right: none;
  }
  .featured-boxes-inner .col-lg-3:nth-child(3),
  .featured-boxes-inner .col-lg-3:nth-child(4) {
    border-bottom: none;
  }
  .services-area .container-fluid {
    max-width: 720px;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .overview-box .overview-content {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    padding-left: 15px;
    padding-right: 15px;
  }
  .overview-box .overview-content .content {
    padding-left: 0;
    max-width: 600px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  .overview-box .overview-content .content .bar {
    margin-left: auto;
    margin-right: auto;
  }
  .overview-box .overview-content .content.left-content {
    padding-right: 0;
  }
  .overview-box .overview-content .content .services-list {
    text-align: left;
  }
  .overview-box .overview-content .content .features-list {
    text-align: left;
  }
  .overview-box .overview-image {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    margin-top: 40px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .comparisons-table table thead th {
    white-space: nowrap;
  }
  .comparisons-table table thead th:nth-child(1) {
    padding-left: 20px;
  }
  .comparisons-table table tbody td {
    white-space: nowrap;
  }
  .comparisons-table table tbody td:nth-child(1) {
    padding-left: 20px;
  }
  .single-features-box {
    padding-top: 25px;
    padding-bottom: 25px;
    padding-right: 25px;
    padding-left: 25px;
  }
  .features-section .container-fluid {
    max-width: 720px;
  }
  .features-box-list {
    margin-left: 0;
    max-width: 100%;
  }
  .features-box {
    text-align: center;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-right: 25px;
    padding-left: 25px;
  }
  .features-box .icon {
    position: relative;
    left: 0;
    top: 0;
    margin: 0 auto 20px;
  }
  .features-image {
    margin-top: 40px;
  }
  .single-team-member {
    text-align: center;
  }
  .single-team-member .member-image {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .single-team-member .member-content {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 20px;
  }
  .invoicing-area {
    padding-bottom: 30px;
  }
  .invoicing-area .container-fluid {
    max-width: 720px;
  }
  .invoicing-content {
    text-align: center;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
  .invoicing-content .bar {
    margin-left: auto;
    margin-right: auto;
  }
  .invoicing-image {
    margin-top: 30px;
  }
  .invoicing-image .main-image img:nth-child(2) {
    left: -25px;
    top: 0;
  }
  .invoicing-image .main-image img:nth-child(3) {
    left: -25px;
    top: 170px;
  }
  .invoicing-image .main-image img:nth-child(4) {
    right: -30px;
    top: 60px;
  }
  .invoicing-image .circle-image {
    display: none;
  }
  .funfacts-area .row {
    padding-left: 55px;
    padding-right: 55px;
  }
  .app-download-area {
    padding-top: 70px;
  }
  .app-download-area .row .col-lg-6:first-child {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .app-download-area .row .col-lg-6:last-child {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .app-download-content {
    text-align: center;
    padding-left: 0;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
  .app-download-content .bar {
    margin-left: auto;
    margin-right: auto;
  }
  .app-image {
    text-align: center;
    margin-top: 70px;
  }
  .app-image .main-image {
    display: none;
  }
  .app-image .main-mobile-image {
    display: block;
  }
  .faq-content {
    text-align: center;
  }
  .faq-content .bar {
    margin-left: auto;
    margin-right: auto;
  }
  .faq-accordion {
    margin-top: 30px;
  }
  .blog-area .widget-area {
    margin-top: 10px;
    margin-bottom: 30px;
  }
  .widget-area {
    margin-top: 40px;
  }
  .login-area .col-lg-6:first-child {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .login-area .col-lg-6:last-child {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .login-content {
    height: 100%;
    padding-top: 70px;
    padding-bottom: 70px;
    padding-right: 15px;
    padding-left: 15px;
  }
  .login-image {
    height: auto;
    background-image: unset;
  }
  .login-image img {
    display: block;
  }
  .signup-area .col-lg-6:first-child {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .signup-area .col-lg-6:last-child {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .signup-content {
    height: 100%;
    padding-top: 70px;
    padding-bottom: 70px;
    padding-right: 15px;
    padding-left: 15px;
  }
  .signup-image {
    height: auto;
    background-image: unset;
  }
  .signup-image img {
    display: block;
  }
  .page-title-section {
    padding-top: 170px;
    padding-bottom: 100px;
  }
  .contact-info {
    padding-right: 0;
    margin-bottom: 40px;
  }
  .home-area {
    padding-top: 140px;
    padding-bottom: 180px;
  }
  .banner-item-content {
    text-align: center;
  }
  .banner-item-content h1 {
    font-size: 45px;
  }
  .banner-item-content p {
    font-size: 16px;
    margin-left: auto;
    margin-right: auto;
  }
  .banner-item-content .btn {
    margin-top: 20px;
  }
  .banner-item-image {
    margin-left: 0;
    margin-top: 30px;
  }
  .home-slides.owl-theme .owl-nav {
    top: 0;
    right: 0;
    opacity: 1;
    -webkit-transform: unset;
    transform: unset;
    margin-top: 30px;
    position: relative;
    visibility: visible;
  }
  .home-slides.owl-theme .owl-nav [class*="owl-"] {
    width: 45px;
    height: 45px;
    margin: 0 5px;
    font-size: 20px;
    display: inline-block;
  }
  .banner-wrapper {
    padding-top: 130px;
  }
  .banner-wrapper .container-fluid {
    max-width: 720px;
  }
  .banner-wrapper-content {
    padding-right: 0;
    text-align: center;
    margin-right: auto;
    margin-top: 0;
    margin-left: auto;
  }
  .banner-wrapper-content h1 {
    font-size: 45px;
  }
  .banner-wrapper-content p {
    margin-left: auto;
    margin-right: auto;
  }
  .banner-wrapper-image {
    text-align: center;
    margin-top: 20px;
  }
  .payment-experience-content {
    text-align: center;
    margin-bottom: 30px;
  }
  .single-payment-experience-box {
    text-align: center;
  }
  .single-payment-experience-box .icon {
    margin-left: auto;
    margin-right: auto;
  }
  .support-image {
    max-width: 535px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 35px;
  }
  .support-content {
    padding-left: 0;
    text-align: center;
  }
  .business-content {
    text-align: center;
    margin-bottom: 40px;
  }
  .business-image {
    margin-left: 0;
    padding: 35px;
  }
  .success-story-inner {
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 80px;
  }
  .success-story-inner .owl-theme .owl-nav {
    right: -60px;
  }
  .success-story-inner .owl-theme .owl-nav [class*="owl-"] {
    width: 35px;
    height: 35px;
    line-height: 39px;
    font-size: 18px;
  }
  .single-success-story-box .content {
    padding-right: 20px;
  }
  .single-success-story-box .content p {
    font-size: 15px;
  }
  .single-success-story-box .content h3 {
    font-size: 14px;
  }
  .single-success-story-box .content .btn {
    margin-top: 25px;
  }
  .global-content {
    padding-right: 0;
    text-align: center;
    margin-bottom: 35px;
  }
  .global-content ul li {
    padding: 20px;
    font-size: 18px;
  }
  .payment-features-overview .payment-features-image .image {
    margin-right: 0;
    padding: 20px;
  }
  .payment-features-overview .payment-features-content .content {
    padding-left: 0;
  }
  .get-started-title {
    text-align: center;
  }
  .get-started-content {
    text-align: center;
    margin-top: 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .luvion-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
    left: -245px;
  }
  .luvion-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu {
    left: auto;
    right: -250px;
  }
  .luvion-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu {
    left: -250px;
  }
  .luvion-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu {
    left: -250px;
  }
  .luvion-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu {
    left: 215px;
  }
  .luvion-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu {
    left: 215px;
  }
  .money-transfer-form {
    margin-left: 20px;
  }
  .hero-content h1 {
    font-size: 49px;
  }
  .hero-image .main-image img:nth-child(3) {
    right: -15px;
    max-width: 35%;
    top: 130px;
  }
  .information-area {
    padding-top: 30px;
  }
  .single-information-box .btn-box .play-store-btn {
    padding: 10px 30px 10px 50px;
    margin-top: 5px;
    margin-left: 0;
  }
  .single-information-box .btn-box .play-store-btn i {
    left: 20px;
  }
  .single-information-box .btn-box .app-store-btn {
    padding: 10px 30px 10px 50px;
  }
  .single-information-box .btn-box .app-store-btn i {
    left: 20px;
  }
  .single-featured-box {
    padding-left: 19px;
    padding-right: 19px;
  }
  .overview-box .overview-content .content {
    max-width: 465px;
    padding-left: 25px;
  }
  .overview-box .overview-content .content.left-content {
    padding-right: 25px;
  }
  .single-features-box {
    padding-top: 25px;
    padding-bottom: 25px;
    padding-right: 25px;
    padding-left: 25px;
  }
  .invoicing-content {
    max-width: 445px;
  }
  .invoicing-image .main-image img:nth-child(2) {
    left: -50px;
    top: -30px;
  }
  .invoicing-image .main-image img:nth-child(3) {
    left: -50px;
    top: 115px;
  }
  .invoicing-image .main-image img:nth-child(4) {
    right: -65px;
    top: 30px;
  }
  .app-image .main-image img:nth-child(1) {
    position: relative;
    left: -20px;
  }
  .app-image .main-image img:nth-child(2) {
    right: -30px;
    top: 0;
  }
  .login-content .login-form {
    max-width: 445px;
  }
  .signup-content .signup-form {
    max-width: 445px;
  }
  .app-download-area {
    padding-top: 85px;
    padding-bottom: 30px;
  }
  .banner-item-content h1 {
    font-size: 43px;
  }
  .banner-item-content p {
    font-size: 16px;
    max-width: 380px;
  }
  .banner-item-content .btn {
    margin-top: 20px;
  }
  .banner-item-image {
    margin-left: -38px;
  }
  .home-slides.owl-theme .owl-nav {
    right: -15px;
  }
  .home-slides.owl-theme .owl-nav [class*="owl-"] {
    width: 45px;
    height: 45px;
    font-size: 20px;
  }
  .banner-wrapper-content {
    max-width: 465px;
  }
  .banner-wrapper-content h1 {
    font-size: 40px;
  }
  .banner-wrapper-content p {
    font-size: 16px;
  }
  .single-payment-experience-box h3 {
    font-size: 20px;
  }
  .business-content .single-business-box h3 {
    font-size: 20px;
  }
  .business-image {
    padding: 30px;
  }
  .single-testimonials-box h3 {
    font-size: 14px;
  }
  .success-story-inner {
    padding-left: 50px;
    padding-right: 100px;
  }
  .success-story-inner .owl-theme .owl-nav {
    right: -78px;
  }
  .single-success-story-box .content p {
    font-size: 19px;
  }
  .payment-features-overview .payment-features-image .image {
    padding: 30px;
  }
}
@media only screen and (min-width: 1199px) and (max-width: 1290px) {
  .hero-image .main-image img:nth-child(3) {
    right: -40px;
  }
}
@media only screen and (min-width: 1550px) {
  .main-banner-section {
    height: 100vh;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .overview-box .overview-content .content {
    max-width: 550px;
  }
  .invoicing-content {
    max-width: 535px;
  }
  .invoicing-image .main-image img:nth-child(2) {
    left: 0;
    top: 0;
  }
  .invoicing-image .main-image img:nth-child(3) {
    left: 0;
  }
  .invoicing-image .main-image img:nth-child(4) {
    right: 0;
  }
  .features-box-list {
    max-width: 435px;
  }
  .banner-wrapper-content {
    max-width: 550px;
  }
  .banner-wrapper-content h1 {
    font-size: 48px;
  }
}
