@import "https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&amp;display=swap";
@import "https://fonts.googleapis.com/css?family=Raleway:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&amp;display=swap";
body {
  padding: 0;
  margin: 0;
  /* font-family: roboto, sans-serif; */
  font-family: Montserrat !important;
  font-size: 15px;
}
img {
  max-width: 100%;
}
.d-table {
  width: 100%;
  height: 100%;
}
.d-table-cell {
  vertical-align: middle;
}
a {
  color: #0e314c;
  text-decoration: none;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: 0 !important;
  padding-left: 2.5px;
  padding-right: 2.5px;
}
a:hover {
  color: #ff0008;
  text-decoration: none;
}
button {
  outline: 0 !important;
}
.ptb-70 {
  padding-top: 70px;
  padding-bottom: 70px;
}
.bg-f7fafd {
  background-color: #f7fafd;
}
.bg-f6f4f8 {
  background-color: #f6f4f8;
}
.bg-f4fcff {
  background-color: #f4fcff;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  /* font-family: raleway, sans-serif; */
  font-family: Montserrat !important;
}
p {
  line-height: 1.7;
  margin-bottom: 15px;
  color: #6084a4;
  font-weight: 300;
  font-size: 15px;
}
p:last-child {
  margin-bottom: 0;
}
.mfp-bg {
  position: fixed !important;
}
.mfp-wrap {
  position: fixed !important;
}
.mfp-container {
  position: fixed;
}
.btn {
  font-weight: 700;
  border: none;
  padding: 14.5px 30px;
  text-transform: uppercase;
  font-size: 14px;
  line-height: initial;
  border-radius: 4px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  position: relative;
  z-index: 1;
}
.btn.disabled,
.btn:disabled {
  cursor: not-allowed;
}
.btn-primary {
  background-color: transparent;
  color: #fff;
}
.btn-primary::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ff0008),
    to(#ff6a00)
  );
  background: linear-gradient(90deg, #ff0008 0%, #ff6a00 100%);
  border-radius: 4px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.btn-primary::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: linear-gradient(135deg, #e7322f 0%, #ff0008 100%);
  border-radius: 4px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}
.btn-primary:hover,
.btn-primary:focus {
  background-color: transparent !important;
  color: #fff !important;
  -webkit-box-shadow: unset !important;
  box-shadow: unset !important;
}
.btn-primary:hover::before,
.btn-primary:focus::before {
  opacity: 0;
  visibility: hidden;
}
.btn-primary:hover::after,
.btn-primary:focus::after {
  opacity: 1;
  visibility: visible;
}
.section-title {
  text-align: center;
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  margin-top: -9px;
}
.section-title h2 {
  margin-bottom: 0;
  font-size: 40px;
  font-weight: 600;
}
.section-title .bar {
  height: 5px;
  width: 90px;
  background: #cdf1d8;
  margin: 15px auto 20px;
  position: relative;
  border-radius: 30px;
}
.section-title .bar::before {
  content: "";
  position: absolute;
  left: 0;
  top: -2.6px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: #44ce6f;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: MOVE-BG;
  animation-name: MOVE-BG;
}
.section-title p {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
}
.form-control2 {
  height: 50px;
  padding: 0 15px;
  font-size: 15px;
  line-height: 50px;
  color: #0e314c;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.form-control2:focus {
  -webkit-box-shadow: unset !important;
  box-shadow: unset !important;
  border-color: #ff0008;
}
.preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ff0008),
    to(#ff6a00)
  );
  background: linear-gradient(90deg, #ff0008 0%, #ff6a00 100%);
  top: 0;
  left: 0;
}
.preloader .loader {
  position: absolute;
  top: 43%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-43%);
  transform: translateY(-43%);
  text-align: center;
  margin: 0 auto;
  width: 50px;
  height: 50px;
}
.preloader .box {
  width: 100%;
  height: 100%;
  background: #fff;
  -webkit-animation: animate 0.5s linear infinite;
  animation: animate 0.5s linear infinite;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 3px;
}
.preloader .shadow {
  width: 100%;
  height: 5px;
  background: #000;
  opacity: 0.1;
  position: absolute;
  top: 59px;
  left: 0;
  border-radius: 50%;
  -webkit-animation: shadow 0.5s linear infinite;
  animation: shadow 0.5s linear infinite;
}
@-webkit-keyframes loader {
  0% {
    left: -100px;
  }
  100% {
    left: 110%;
  }
}
@keyframes loader {
  0% {
    left: -100px;
  }
  100% {
    left: 110%;
  }
}
@-webkit-keyframes animate {
  17% {
    border-bottom-right-radius: 3px;
  }
  25% {
    -webkit-transform: translateY(9px) rotate(22.5deg);
    transform: translateY(9px) rotate(22.5deg);
  }
  50% {
    -webkit-transform: translateY(18px) scale(1, 0.9) rotate(45deg);
    transform: translateY(18px) scale(1, 0.9) rotate(45deg);
    border-bottom-right-radius: 40px;
  }
  75% {
    -webkit-transform: translateY(9px) rotate(67.5deg);
    transform: translateY(9px) rotate(67.5deg);
  }
  100% {
    -webkit-transform: translateY(0) rotate(90deg);
    transform: translateY(0) rotate(90deg);
  }
}
@keyframes animate {
  17% {
    border-bottom-right-radius: 3px;
  }
  25% {
    -webkit-transform: translateY(9px) rotate(22.5deg);
    transform: translateY(9px) rotate(22.5deg);
  }
  50% {
    -webkit-transform: translateY(18px) scale(1, 0.9) rotate(45deg);
    transform: translateY(18px) scale(1, 0.9) rotate(45deg);
    border-bottom-right-radius: 40px;
  }
  75% {
    -webkit-transform: translateY(9px) rotate(67.5deg);
    transform: translateY(9px) rotate(67.5deg);
  }
  100% {
    -webkit-transform: translateY(0) rotate(90deg);
    transform: translateY(0) rotate(90deg);
  }
}
@-webkit-keyframes shadow {
  50% {
    -webkit-transform: scale(1.2, 1);
    transform: scale(1.2, 1);
  }
}
@keyframes shadow {
  50% {
    -webkit-transform: scale(1.2, 1);
    transform: scale(1.2, 1);
  }
}
.luvion-responsive-nav {
  display: none;
}
.navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
}
.navbar-brand img {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.navbar-brand img:nth-child(2) {
  display: none;
}
.luvion-nav {
  background-color: transparent;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
  padding-left: 0;
}
.luvion-nav .navbar {
  padding-right: 0;
  padding-top: 0;
  padding-left: 0;
  padding-bottom: 0;
}
.luvion-nav .navbar ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}
.luvion-nav .navbar .navbar-nav {
  /* font-family: raleway, sans-serif; */
  font-family: Montserrat !important;
  margin-left: auto;
}
.luvion-nav .navbar .navbar-nav .nav-item {
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 0;
  padding-right: 0;
}
.luvion-nav .navbar .navbar-nav .nav-item a {
  font-size: 17px;
  font-weight: 500;
  color: #e5e1e1;
  text-transform: capitalize;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-left: 15px;
  margin-right: 15px;
}
.luvion-nav .navbar .navbar-nav .nav-item a:hover,
.luvion-nav .navbar .navbar-nav .nav-item a:focus,
.luvion-nav .navbar .navbar-nav .nav-item a.active {
  color: #fff;
}
.luvion-nav .navbar .navbar-nav .nav-item a i {
  font-size: 10px;
  margin-left: 1px;
}
.luvion-nav .navbar .navbar-nav .nav-item:last-child a {
  margin-right: 0;
}
.luvion-nav .navbar .navbar-nav .nav-item:first-child a {
  margin-left: 0;
}
.luvion-nav .navbar .navbar-nav .nav-item:hover a,
.luvion-nav .navbar .navbar-nav .nav-item.active a {
  color: #fff;
}
.luvion-nav .navbar .navbar-nav .nav-item .dropdown-menu {
  -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  position: absolute;
  border: none;
  top: 80px;
  left: 0;
  width: 250px;
  z-index: 99;
  display: block;
  opacity: 0;
  visibility: hidden;
  border-radius: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding-top: 20px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 20px;
}
.luvion-nav .navbar .navbar-nav .nav-item .dropdown-menu li {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.luvion-nav .navbar .navbar-nav .nav-item .dropdown-menu li a {
  text-transform: capitalize;
  padding: 8px 15px;
  margin: 0;
  color: #0e314c;
  font-size: 15.5px;
  font-weight: 500;
}
.luvion-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:hover,
.luvion-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:focus,
.luvion-nav .navbar .navbar-nav .nav-item .dropdown-menu li a.active {
  color: #ff0008;
}
.luvion-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
  left: -250px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
.luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  a {
  color: #0e314c;
}
.luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:hover,
.luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:focus,
.luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  a.active {
  color: #ff0008;
}
.luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu {
  left: 220px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
.luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a {
  color: #0e314c;
}
.luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:hover,
.luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:focus,
.luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a.active {
  color: #ff0008;
}
.luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu {
  left: -250px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
.luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a {
  color: #0e314c;
}
.luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:hover,
.luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:focus,
.luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a.active {
  color: #ff0008;
}
.luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu {
  left: -250px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
.luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a {
  color: #0e314c;
}
.luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:hover,
.luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:focus,
.luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a.active {
  color: #ff0008;
}
.luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu {
  left: -250px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
.luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a {
  color: #0e314c;
}
.luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:hover,
.luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:focus,
.luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a.active {
  color: #ff0008;
}
.luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu {
  left: -250px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
.luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a {
  color: #0e314c;
}
.luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:hover,
.luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:focus,
.luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a.active {
  color: #ff0008;
}
.luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li.active
  a {
  color: #ff0008;
}
.luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li:hover
  .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}
.luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li.active
  a {
  color: #ff0008;
}
.luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li:hover
  .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}
.luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li.active
  a {
  color: #ff0008;
}
.luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li:hover
  .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}
.luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li.active
  a {
  color: #ff0008;
}
.luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li:hover
  .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}
.luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li.active
  a {
  color: #ff0008;
}
.luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li:hover
  .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}
.luvion-nav .navbar .navbar-nav .nav-item .dropdown-menu li.active a {
  color: #ff0008;
}
.luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li:hover
  .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}
.luvion-nav .navbar .navbar-nav .nav-item:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: 100%;
}
.luvion-nav .navbar .others-options {
  /* margin-left: 25px; */
  margin-left: auto;
}
.luvion-nav .navbar .others-options .login-btn {
  color: #fff;
  font-size: 17px;
  font-weight: 500;
  /* font-family: raleway, sans-serif; */
  font-family: Montserrat !important;
}
.luvion-nav .navbar .others-options .login-btn i {
  margin-right: 2px;
  color: #eae563;
}
.luvion-nav .navbar .others-options .login-btn:hover {
  color: #fff;
}
.navbar-area {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
  z-index: 999;
  background-color: transparent;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  padding-top: 20px;
  padding-bottom: 20px;
}
.navbar-area.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  /* background-color: #fff !important; */
  background-color: transparent !important;
  -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.navbar-area.is-sticky .navbar-brand img:nth-child(1) {
  display: none;
}
.navbar-area.is-sticky .navbar-brand img:nth-child(2) {
  display: block;
}
.navbar-area.is-sticky .luvion-nav {
  /* background-color: #fff; */
  background-color: transparent !important;
}
.navbar-area.is-sticky .luvion-nav .navbar .navbar-nav .nav-item a {
  color: #000320;
}
.navbar-area.is-sticky .luvion-nav .navbar .navbar-nav .nav-item a:hover,
.navbar-area.is-sticky .luvion-nav .navbar .navbar-nav .nav-item a:focus,
.navbar-area.is-sticky .luvion-nav .navbar .navbar-nav .nav-item a.active {
  color: #ff0008;
}
.navbar-area.is-sticky .luvion-nav .navbar .navbar-nav .nav-item:hover a,
.navbar-area.is-sticky .luvion-nav .navbar .navbar-nav .nav-item.active a {
  color: #ff0008;
}
.navbar-area.is-sticky
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  a {
  color: #0e314c;
}
.navbar-area.is-sticky
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  a:hover,
.navbar-area.is-sticky
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  a:focus,
.navbar-area.is-sticky
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  a.active {
  color: #ff0008;
}
.navbar-area.is-sticky
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  a {
  color: #0e314c;
}
.navbar-area.is-sticky
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:hover,
.navbar-area.is-sticky
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:focus,
.navbar-area.is-sticky
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  a.active {
  color: #ff0008;
}
.navbar-area.is-sticky
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a {
  color: #0e314c;
}
.navbar-area.is-sticky
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:hover,
.navbar-area.is-sticky
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:focus,
.navbar-area.is-sticky
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a.active {
  color: #ff0008;
}
.navbar-area.is-sticky
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a {
  color: #0e314c;
}
.navbar-area.is-sticky
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:hover,
.navbar-area.is-sticky
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:focus,
.navbar-area.is-sticky
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a.active {
  color: #ff0008;
}
.navbar-area.is-sticky
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a {
  color: #0e314c;
}
.navbar-area.is-sticky
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:hover,
.navbar-area.is-sticky
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:focus,
.navbar-area.is-sticky
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a.active {
  color: #ff0008;
}
.navbar-area.is-sticky
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a {
  color: #0e314c;
}
.navbar-area.is-sticky
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:hover,
.navbar-area.is-sticky
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:focus,
.navbar-area.is-sticky
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a.active {
  color: #ff0008;
}
.navbar-area.is-sticky
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a {
  color: #0e314c;
}
.navbar-area.is-sticky
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:hover,
.navbar-area.is-sticky
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:focus,
.navbar-area.is-sticky
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a.active {
  color: #ff0008;
}
.navbar-area.is-sticky
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li.active
  a {
  color: #ff0008;
}
.navbar-area.is-sticky
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li.active
  a {
  color: #ff0008;
}
.navbar-area.is-sticky
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li.active
  a {
  color: #ff0008;
}
.navbar-area.is-sticky
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li.active
  a {
  color: #ff0008;
}
.navbar-area.is-sticky
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li.active
  a {
  color: #ff0008;
}
.navbar-area.is-sticky
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li.active
  a {
  color: #ff0008;
}
.navbar-area.is-sticky .luvion-nav .navbar .others-options .login-btn {
  color: #0e314c;
}
.navbar-area.is-sticky .luvion-nav .navbar .others-options .login-btn i {
  color: #ff0008;
}
.navbar-area.is-sticky .luvion-nav .navbar .others-options .login-btn:hover {
  color: #ff0008;
}
.navbar-area.navbar-style-two .navbar-brand img {
  display: block !important;
}
.navbar-area.navbar-style-two .luvion-nav .navbar .navbar-nav .nav-item a {
  color: #0e314c;
}
.navbar-area.navbar-style-two .luvion-nav .navbar .navbar-nav .nav-item a:hover,
.navbar-area.navbar-style-two .luvion-nav .navbar .navbar-nav .nav-item a:focus,
.navbar-area.navbar-style-two
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  a.active {
  color: #ff0008;
}
.navbar-area.navbar-style-two .luvion-nav .navbar .navbar-nav .nav-item:hover a,
.navbar-area.navbar-style-two
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item.active
  a {
  color: #ff0008;
}
.navbar-area.navbar-style-two
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  a {
  color: #0e314c;
}
.navbar-area.navbar-style-two
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  a:hover,
.navbar-area.navbar-style-two
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  a:focus,
.navbar-area.navbar-style-two
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  a.active {
  color: #ff0008;
}
.navbar-area.navbar-style-two
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  a {
  color: #0e314c;
}
.navbar-area.navbar-style-two
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:hover,
.navbar-area.navbar-style-two
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:focus,
.navbar-area.navbar-style-two
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  a.active {
  color: #ff0008;
}
.navbar-area.navbar-style-two
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a {
  color: #0e314c;
}
.navbar-area.navbar-style-two
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:hover,
.navbar-area.navbar-style-two
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:focus,
.navbar-area.navbar-style-two
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a.active {
  color: #ff0008;
}
.navbar-area.navbar-style-two
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a {
  color: #0e314c;
}
.navbar-area.navbar-style-two
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:hover,
.navbar-area.navbar-style-two
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:focus,
.navbar-area.navbar-style-two
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a.active {
  color: #ff0008;
}
.navbar-area.navbar-style-two
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a {
  color: #0e314c;
}
.navbar-area.navbar-style-two
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:hover,
.navbar-area.navbar-style-two
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:focus,
.navbar-area.navbar-style-two
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a.active {
  color: #ff0008;
}
.navbar-area.navbar-style-two
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a {
  color: #0e314c;
}
.navbar-area.navbar-style-two
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:hover,
.navbar-area.navbar-style-two
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:focus,
.navbar-area.navbar-style-two
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a.active {
  color: #ff0008;
}
.navbar-area.navbar-style-two
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a {
  color: #0e314c;
}
.navbar-area.navbar-style-two
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:hover,
.navbar-area.navbar-style-two
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a:focus,
.navbar-area.navbar-style-two
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  a.active {
  color: #ff0008;
}
.navbar-area.navbar-style-two
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li.active
  a {
  color: #ff0008;
}
.navbar-area.navbar-style-two
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li.active
  a {
  color: #ff0008;
}
.navbar-area.navbar-style-two
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li.active
  a {
  color: #ff0008;
}
.navbar-area.navbar-style-two
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu
  li.active
  a {
  color: #ff0008;
}
.navbar-area.navbar-style-two
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li.active
  a {
  color: #ff0008;
}
.navbar-area.navbar-style-two
  .luvion-nav
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  li.active
  a {
  color: #ff0008;
}
.navbar-area.navbar-style-two .luvion-nav .others-options .login-btn {
  color: #0e314c;
}
.navbar-area.navbar-style-two .luvion-nav .others-options .login-btn i {
  color: #0e314c;
}
.navbar-area.navbar-style-two .luvion-nav .others-options .login-btn:hover {
  color: #ff0008;
}
.luvion-responsive-nav .mean-container a.meanmenu-reveal span {
  top: 8px;
  height: 4px;
  margin-top: -6px;
  position: relative;
}
@media only screen and (max-width: 991px) {
  .luvion-responsive-nav {
    display: block;
  }
  .luvion-responsive-nav .luvion-responsive-menu {
    position: relative;
  }
  .luvion-responsive-nav .luvion-responsive-menu.mean-container .mean-nav ul {
    font-size: 14px;
  }
  .luvion-responsive-nav
    .luvion-responsive-menu.mean-container
    .mean-nav
    ul
    li
    a.active {
    color: #ff0008;
  }
  .luvion-responsive-nav
    .luvion-responsive-menu.mean-container
    .mean-nav
    ul
    li
    li
    a {
    font-size: 13.5px;
  }
  .luvion-responsive-nav .luvion-responsive-menu.mean-container .navbar-nav {
    overflow-y: scroll;
    height: 350px;
    -webkit-box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
  }
  .luvion-responsive-nav .mean-container a.meanmenu-reveal {
    color: #fff;
  }
  .luvion-responsive-nav .mean-container a.meanmenu-reveal span {
    background: #fff;
  }
  .luvion-responsive-nav .logo {
    position: relative;
    width: 100%;
    z-index: 999;
  }
  .luvion-responsive-nav .logo a img:nth-child(2) {
    display: none;
  }
  .luvion-responsive-nav .others-options {
    position: absolute;
    /* right: 52px; */
    right: 0;
    top: 3px;
  }
  .luvion-responsive-nav .others-options .showe {
    visibility: hidden;
  }

  .luvion-responsive-nav .others-options .hide {
    visibility: hidden;
  }

  .luvion-responsive-nav .others-options .showe {
    visibility: visible;
    margin-left: 64px;
  }
  .luvion-responsive-nav .others-options .login-btn {
    color: #fff;
    font-size: 15px;
    font-weight: 400;
    /* font-family: raleway, sans-serif; */
    font-family: Montserrat !important;
  }
  .luvion-responsive-nav .others-options .login-btn i {
    margin-right: 2px;
    color: #eae563;
  }
  .luvion-responsive-nav .others-options .login-btn:hover {
    color: #ff0008;
  }
  .navbar-area {
    border-bottom: 1px solid rgba(255, 255, 255, 0.09);
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .navbar-area.is-sticky {
    border-bottom: none;
    -webkit-box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
  }
  .navbar-area.is-sticky
    .luvion-responsive-nav
    .mean-container
    a.meanmenu-reveal {
    color: #0e314c;
  }
  .navbar-area.is-sticky
    .luvion-responsive-nav
    .mean-container
    a.meanmenu-reveal
    span {
    background: #0e314c;
  }
  .navbar-area.is-sticky .luvion-responsive-nav .logo a img:nth-child(1) {
    display: none;
  }
  .navbar-area.is-sticky .luvion-responsive-nav .logo a img:nth-child(2) {
    display: block;
  }
  .navbar-area.is-sticky .luvion-responsive-nav .others-options .login-btn {
    color: #0e314c;
  }
  .navbar-area.is-sticky .luvion-responsive-nav .others-options .login-btn i {
    color: #ff0008;
  }
  .navbar-area.is-sticky
    .luvion-responsive-nav
    .others-options
    .login-btn:hover {
    color: #ff0008;
  }
  .navbar-area.navbar-style-two
    .luvion-responsive-nav
    .mean-container
    a.meanmenu-reveal {
    color: #0e314c;
  }
  .navbar-area.navbar-style-two
    .luvion-responsive-nav
    .mean-container
    a.meanmenu-reveal
    span {
    background: #0e314c;
  }
  .navbar-area.navbar-style-two .luvion-responsive-nav .logo a img {
    display: inline-block !important;
  }
  .navbar-area.navbar-style-two
    .luvion-responsive-nav
    .others-options
    .login-btn {
    color: #0e314c;
  }
  .navbar-area.navbar-style-two
    .luvion-responsive-nav
    .others-options
    .login-btn
    i {
    color: #ff0008;
  }
  .navbar-area.navbar-style-two
    .luvion-responsive-nav
    .others-options
    .login-btn:hover {
    color: #ff0008;
  }
  .luvion-nav {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .main-banner {
    /* height: 110vh; */
    background-image: url(../img/Cropped.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  /* .main-banner {
    background-image: url(../img/Cropped.jpg);
    background-size: cover;
    background-repeat: no-repeat;
  } */

  /* .main-banner-small-screen {
    display: block;
  } */
}

.main-banner {
  height: 110vh;
  background-image: url(../img/ICONB.png);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.main-banner-content {
  margin-top: -20px;
  max-width: 600px;
}
.main-banner-content h1 {
  margin-bottom: 0;
  color: #fff;
  font-size: 52px;
  font-weight: 700;
}
.main-banner-content p {
  color: #fff;
  /* font-family: raleway, sans-serif; */
  font-family: Montserrat !important;
  font-size: 18px;
  max-width: 400px;
  margin-top: 20px;
  margin-bottom: 0;
}
.main-banner-content .btn {
  margin-top: 30px;
}
.main-banner-section {
  height: 800px;
  background-image: url(../img/ICONB.png);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.banner-content {
  margin-top: -50px;
}
.banner-content h1 {
  margin-bottom: 0;
  color: #fff;
  font-size: 50px;
  font-weight: 700;
}
.banner-content p {
  color: #fff;
  /* font-family: raleway, sans-serif; */
  font-family: Montserrat !important;
  font-size: 18px;
  max-width: 400px;
  margin-top: 20px;
  margin-bottom: 0;
}
.banner-content .btn {
  margin-top: 30px;
}
.money-transfer-form {
  background-color: #fff;
  -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  position: relative;
  z-index: 1;
  padding: 30px;
  border-radius: 5px;
  margin-top: -50px;
  margin-left: 100px;
}
.money-transfer-form::before {
  content: "";
  position: absolute;
  z-index: -1;
  background: #fff;
  width: 96%;
  opacity: 0.62;
  height: 50%;
  bottom: -10px;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 3px;
}
.money-transfer-form::after {
  content: "";
  position: absolute;
  z-index: -1;
  background: #fff;
  width: 96%;
  opacity: 0.62;
  height: 50%;
  top: -10px;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 3px;
}
.money-transfer-form form {
  text-align: center;
}
.money-transfer-form form .form-group {
  margin-bottom: 15px;
  position: relative;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ff0008),
    to(#ff6a00)
  );
  background: linear-gradient(90deg, #ff0008 0%, #ff6a00 100%);
  padding: 36px 10px 12px 15px;
  border-radius: 5px;
}
.money-transfer-form form .form-group .amount-currency-select {
  right: 0;
  top: 0;
  position: absolute;
  height: 100%;
}
.money-transfer-form form .form-group .nice-select {
  border: none;
  background-color: #0e314c;
  border-radius: 0 5px 5px 0;
  height: 100%;
  padding: 0 35px 0 25px;
  line-height: 72px;
  font-size: 17px;
  font-weight: 700;
  color: white;
}
.money-transfer-form form .form-group .nice-select:after {
  right: 20px;
  border-color: #fff;
  width: 8px;
  height: 8px;
}
.money-transfer-form form .form-group .nice-select span {
  color: #fff;
}
.money-transfer-form form .form-group .nice-select .list {
  -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  width: 100%;
  padding-top: 20px;
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 20px;
  margin-top: 0;
  margin-bottom: 0;
}
.money-transfer-form form .form-group .nice-select .list .option {
  line-height: initial;
  min-height: auto;
  text-align: center;
  margin-top: 12px;
  padding-left: 0;
  padding-right: 0;
}
.money-transfer-form form .form-group .nice-select .list .option:hover,
.money-transfer-form form .form-group .nice-select .list .option:focus,
.money-transfer-form form .form-group .nice-select .list .option.focus,
.money-transfer-form form .form-group .nice-select .list .option.selected {
  background-color: transparent;
}
.money-transfer-form form .form-group .nice-select .list .option:hover {
  color: #ff0008;
}
.money-transfer-form form .form-group .nice-select .list .option:first-child {
  margin-top: 0;
}
.money-transfer-form form .form-group label {
  position: absolute;
  left: 15px;
  top: 10px;
  color: #f9f9f9;
  margin-bottom: 0;
  display: block;
  font-weight: 300;
  font-size: 13.5px;
}
.money-transfer-form form .form-group .form-control2 {
  background-color: transparent;
  border: none;
  padding-left: 0;
  height: auto;
  line-height: initial;
  padding-right: 95px;
  color: #fff;
  font-size: 17px;
  font-weight: 500;
}
.money-transfer-form
  form
  .form-group
  .form-control2::-webkit-input-placeholder {
  color: #fff;
}
.money-transfer-form form .form-group .form-control2:-ms-input-placeholder {
  color: #fff;
}
.money-transfer-form form .form-group .form-control2::-ms-input-placeholder {
  color: #fff;
}
.money-transfer-form form .form-group .form-control2::placeholder {
  color: #fff;
}
.money-transfer-form form .currency-info {
  margin-bottom: 25px;
  margin-top: 25px;
  text-align: left;
  position: relative;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;
}
.money-transfer-form form .currency-info .bar {
  height: 100%;
  width: 2px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ff0008),
    to(#ff6a00)
  );
  background: linear-gradient(90deg, #ff0008 0%, #ff6a00 100%);
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.money-transfer-form form .currency-info .bar::before {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  content: "";
  position: absolute;
  top: -1px;
  left: -2px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ff0008),
    to(#ff6a00)
  );
  background: linear-gradient(90deg, #ff0008 0%, #ff6a00 100%);
}
.money-transfer-form form .currency-info .bar::after {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  content: "";
  position: absolute;
  bottom: -1px;
  left: -2px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ff0008),
    to(#ff6a00)
  );
  background: linear-gradient(90deg, #ff0008 0%, #ff6a00 100%);
}
.money-transfer-form form .currency-info span {
  display: inline-block;
  color: #6084a4;
  font-size: 14px;
}
.money-transfer-form form .currency-info span strong {
  color: #0e314c;
}
.money-transfer-form form .currency-info span:last-child {
  margin-left: 15px;
}
.money-transfer-form form .money-transfer-info {
  color: #6084a4;
  font-size: 14px;
}
.money-transfer-form form .money-transfer-info strong {
  display: block;
  margin-top: 3px;
  color: #0e314c;
  font-size: 16px;
}
.money-transfer-form form button {
  margin-top: 15px;
}
.money-transfer-form form .btn::before {
  background: linear-gradient(135deg, #e7322f 0%, #ff0008 100%);
}
.money-transfer-form form .btn::after {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ff0008),
    to(#ff6a00)
  );
  background: linear-gradient(90deg, #ff0008 0%, #ff6a00 100%);
}
.money-transfer-form form .terms-info {
  margin-top: 15px;
}
.money-transfer-form form .terms-info p {
  font-size: 14px;
}
.money-transfer-form form .terms-info p a {
  display: inline-block;
  color: #0e314c;
}
.money-transfer-form form .terms-info p a:hover {
  color: #ff0008;
}
.banner-section {
  position: relative;
  z-index: 1;
  background: transparent url(../img/shape-bg.png) left bottom no-repeat;
  padding-top: 160px;
}
.hero-content {
  margin-top: -50px;
}
.hero-content h1 {
  margin-bottom: 0;
  font-size: 50px;
  font-weight: 700;
}
.hero-content p {
  /* font-family: raleway, sans-serif; */
  font-family: Montserrat !important;
  font-size: 18px;
  max-width: 400px;
  margin-top: 20px;
  margin-bottom: 0;
}
.hero-content .btn {
  margin-top: 30px;
}
.hero-image {
  text-align: center;
  position: relative;
  z-index: 1;
}
.hero-image .main-image img:nth-child(2) {
  position: absolute;
  left: -30px;
  bottom: 60px;
  border-radius: 5px;
}
.hero-image .main-image img:nth-child(3) {
  position: absolute;
  right: -80px;
  top: 110px;
}
.hero-image .main-mobile-image {
  display: none;
}
.hero-image .circle-image {
  position: absolute;
  left: 0;
  right: 0;
  top: -15px;
  margin: 0 auto;
  z-index: -1;
}
.hero-image .video-btn {
  display: inline-block;
  position: absolute;
  z-index: 1;
  color: #fff;
  border-radius: 30px;
  padding: 10px 25px;
  bottom: 55px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  /* font-family: raleway, sans-serif; */
  font-family: Montserrat !important;
  font-weight: 500;
  font-size: 15px;
}
.hero-image .video-btn i {
  margin-right: 2px;
}
.hero-image .video-btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #e7322f 0%, #ff0008 100%);
  z-index: -1;
  border-radius: 30px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.hero-image .video-btn::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ff0008),
    to(#ff6a00)
  );
  background: linear-gradient(90deg, #ff0008 0%, #ff6a00 100%);
  border-radius: 30px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}
.hero-image .video-btn:hover::after {
  opacity: 1;
  visibility: visible;
}
.hero-image .video-btn:hover::before {
  opacity: 0;
  visibility: hidden;
}
.home-area {
  z-index: 1;
  position: relative;
  padding-top: 200px;
  padding-bottom: 250px;
  background-image: url(../img/banner-image/banner-bg.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.home-area::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  z-index: -1;
  opacity: 0.85;
  position: absolute;
  background-color: #3059bc;
}
.home-area:hover .home-slides.owl-theme .owl-nav {
  visibility: visible;
  opacity: 1;
}
.banner-item {
  position: relative;
}
.banner-item-content {
  z-index: 1;
  position: relative;
}
.banner-item-content h1 {
  margin-bottom: 0;
  color: #fff;
  font-size: 60px;
  font-weight: 700;
}
.banner-item-content p {
  color: #fff;
  max-width: 500px;
  /* font-family: raleway, sans-serif; */
  font-family: Montserrat !important;
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 0;
}
.banner-item-content .btn {
  margin-top: 30px;
}
.banner-item-image {
  margin-left: -48px;
}
.home-slides.owl-theme .owl-nav {
  top: 50%;
  opacity: 0;
  right: -25px;
  margin-top: 0;
  position: absolute;
  visibility: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.home-slides.owl-theme .owl-nav [class*="owl-"] {
  padding: 0;
  width: 50px;
  height: 50px;
  margin: 5px 0;
  display: block;
  font-size: 20px;
  border-radius: 0;
  color: #0e314c;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-color: #fff;
}
.home-slides.owl-theme .owl-nav [class*="owl-"]:hover {
  background-color: #ff0008;
  color: #fff;
}
.owl-item.active .banner-item-content {
  overflow: hidden;
}
.owl-item.active .banner-item-content h1 {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.owl-item.active .banner-item-content p {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}
.owl-item.active .banner-item-content .btn {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}
.owl-item.active .banner-item-image {
  overflow: hidden;
}
.owl-item.active .banner-item-image img {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}
.about-content {
  padding-right: 25px;
}
.about-content span {
  display: block;
  margin-bottom: 10px;
  text-transform: uppercase;
  color: #ff0008;
  font-size: 12.5px;
  /* font-family: raleway, sans-serif; */
  font-family: Montserrat !important;
  font-weight: 700;
}
.about-content h2 {
  font-size: 40px;
  font-weight: 700;
}
.about-content p {
  line-height: 1.8;
  color: #5d7079;
  margin-bottom: 0;
  margin-top: 12px;
}
.about-image {
  position: relative;
}
.about-image .video-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  width: 60px;
  height: 60px;
  line-height: 61px;
  text-align: center;
  border-radius: 50%;
  background: linear-gradient(135deg, #e7322f 0%, #ff0008 100%);
  color: #fff;
  font-size: 23px;
  z-index: 1;
}
.about-image .video-btn::after {
  z-index: -1;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 60px;
  height: 60px;
  -webkit-animation: ripple 1.9s ease-out infinite;
  animation: ripple 1.9s ease-out infinite;
  opacity: 0;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5);
}
.about-image .video-btn::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ff0008),
    to(#ff6a00)
  );
  background: linear-gradient(90deg, #ff0008 0%, #ff6a00 100%);
  z-index: -1;
  border-radius: 50%;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.about-image .video-btn:hover::before {
  opacity: 0;
  visibility: hidden;
}
@-webkit-keyframes ripple {
  0%,
  35% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  50% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0.8;
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(2);
    transform: scale(2);
  }
}
@keyframes ripple {
  0%,
  35% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  50% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0.8;
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(2);
    transform: scale(2);
  }
}
.featured-boxes-area {
  position: relative;
  z-index: 1;
  margin-top: -100px;
}
.featured-boxes-inner {
  background-color: #fff;
  border-radius: 5px;
  -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}
.featured-boxes-inner .col-lg-3 {
  border-right: 1px solid #eee;
}
.featured-boxes-inner .col-lg-3:last-child {
  border-right: none;
}
.single-featured-box {
  text-align: center;
  position: relative;
  padding: 115px 25px 40px;
  overflow: hidden;
}
.single-featured-box .icon {
  -webkit-transition: 0.4s;
  transition: 0.4s;
  color: #ff0008;
  position: absolute;
  top: 40px;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  margin-top: -12px;
}
.single-featured-box .icon i::before {
  font-size: 55px;
}
.single-featured-box .icon.color-fb7756 {
  color: #fb7756;
}
.single-featured-box .icon.color-facd60 {
  color: #facd60;
}
.single-featured-box .icon.color-1ac0c6 {
  color: #1ac0c6;
}
.single-featured-box h3 {
  -webkit-transition: 0.4s;
  transition: 0.4s;
  margin-bottom: 0;
  margin-top: 5px;
  font-size: 22px;
  font-weight: 600;
}
.single-featured-box p {
  -webkit-transition: 0.4s;
  transition: 0.4s;
  font-size: 14px;
  margin-top: 12px;
  margin-bottom: 0;
}
.single-featured-box .read-more-btn {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ff0008),
    to(#ff6a00)
  );
  background: linear-gradient(90deg, #ff0008 0%, #ff6a00 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.6;
  transition: 0.6;
  font-size: 15px;
  font-weight: 400;
}
.single-featured-box .read-more-btn::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 1px;
  width: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  height: 1px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ff0008),
    to(#ff6a00)
  );
  background: linear-gradient(90deg, #ff0008 0%, #ff6a00 100%);
}
.single-featured-box .read-more-btn:hover::before {
  width: 100%;
}
.single-featured-box:hover .icon {
  top: 23px;
  -webkit-animation: iconTop 0.4s ease-in-out;
  animation: iconTop 0.4s ease-in-out;
}
.single-featured-box:hover h3 {
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
}
.single-featured-box:hover p {
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
}
.single-featured-box:hover .read-more-btn {
  opacity: 1;
  visibility: visible;
  bottom: 23px;
}
@-webkit-keyframes iconTop {
  0% {
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
  25% {
    opacity: 0;
    -webkit-transform: translate(-50%, -70%);
    transform: translate(-50%, -70%);
  }
  50% {
    opacity: 0;
    -webkit-transform: translate(-50%, -40%);
    transform: translate(-50%, -40%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
}
@keyframes iconTop {
  0% {
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
  25% {
    opacity: 0;
    -webkit-transform: translate(-50%, -70%);
    transform: translate(-50%, -70%);
  }
  50% {
    opacity: 0;
    -webkit-transform: translate(-50%, -40%);
    transform: translate(-50%, -40%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
}
.services-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.overview-box {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center !important;
  -webkit-box-align: center !important;
  align-items: center !important;
}
.overview-box .overview-content {
  -ms-flex: 0 0 50%;
  -webkit-box-flex: 0;
  flex: 0 0 50%;
  max-width: 50%;
}

.overview-box .overview-content .content {
  max-width: 640px;
  padding-left: 50px;
}
.overview-box .overview-content .content .sub-title {
  display: block;
  margin-bottom: 10px;
  text-transform: uppercase;
  color: #ff0008;
  font-size: 12.5px;
  /* font-family: raleway, sans-serif; */
  font-family: Montserrat !important;
  font-weight: 700;
}
.overview-box .overview-content .content.left-content {
  margin-left: auto;
  padding-right: 50px;
  padding-left: 0;
}
.overview-box .overview-content .content h2 {
  margin-bottom: 0;
  font-size: 40px;
  font-weight: 600;
}
.overview-box .overview-content .content .bar {
  height: 5px;
  width: 90px;
  background: #cdf1d8;
  margin: 20px 0 25px;
  position: relative;
  border-radius: 30px;
}
.overview-box .overview-content .content .bar::before {
  content: "";
  position: absolute;
  left: 0;
  top: -2.7px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: #44ce6f;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: MOVE-BG;
  animation-name: MOVE-BG;
}
.overview-box .overview-content .content .services-list {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center !important;
  -webkit-box-align: center !important;
  align-items: center !important;
  padding-left: 0;
  list-style-type: none;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 0;
  margin-top: -5px;
}
.overview-box .overview-content .content .services-list li {
  -ms-flex: 0 0 50%;
  -webkit-box-flex: 0;
  flex: 0 0 50%;
  max-width: 50%;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
}
.overview-box .overview-content .content .services-list li span {
  display: block;
  position: relative;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  color: #6084a4;
  z-index: 1;
  border-radius: 5px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 35px;
  font-size: 14px;
  font-weight: 400;
}
.overview-box .overview-content .content .services-list li span i {
  position: absolute;
  left: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #ff0008;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.overview-box .overview-content .content .services-list li span i::before {
  font-size: 12px;
}
.overview-box .overview-content .content .services-list li span::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 0;
  height: 100%;
  border-radius: 5px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ff0008),
    to(#ff6a00)
  );
  background: linear-gradient(90deg, #ff0008 0%, #ff6a00 100%);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.overview-box .overview-content .content .services-list li span:hover {
  color: #fff;
}
.overview-box .overview-content .content .services-list li span:hover i {
  color: #fff;
}
.overview-box .overview-content .content .services-list li span:hover::before {
  width: 100%;
}
.overview-box .overview-content .content .features-list {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center !important;
  -webkit-box-align: center !important;
  align-items: center !important;
  padding-left: 0;
  list-style-type: none;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 0;
  margin-top: -5px;
}
.overview-box .overview-content .content .features-list li {
  -ms-flex: 0 0 50%;
  -webkit-box-flex: 0;
  flex: 0 0 50%;
  max-width: 50%;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
}
.overview-box .overview-content .content .features-list li span {
  display: block;
  color: #6084a4;
  position: relative;
  padding-left: 31px;
  font-size: 14px;
  font-weight: 400;
}
.overview-box .overview-content .content .features-list li span i {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #fff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  width: 22px;
  height: 20px;
  text-align: center;
  line-height: 18px;
  background: linear-gradient(135deg, #e7322f 0%, #ff0008 100%);
  border-radius: 3px;
}
.overview-box .overview-content .content .features-list li span i::before {
  font-size: 10px;
}
.overview-box .overview-content .content .btn {
  margin-top: 30px;
}
.overview-box .overview-image {
  -ms-flex: 0 0 50%;
  -webkit-box-flex: 0;
  flex: 0 0 50%;
  max-width: 50%;
}
.overview-box .overview-image .images {
  text-align: center;
  position: relative;
  z-index: 1;
}
.overview-box .overview-image .images .circle-img {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: -1;
  right: 0;
  margin: 0 auto;
}
.overview-box .overview-image .images .circle-img img {
  -webkit-animation-name: rotateMe;
  animation-name: rotateMe;
  -webkit-animation-duration: 35s;
  animation-duration: 35s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

.overview-box .custom {
  -ms-flex: 0 0 100%;
  -webkit-box-flex: 0;
  flex: 0 0 100%;
  max-width: 100%;
}

.overview-box .custom .content {
  max-width: 640px;
  padding-left: 50px;
}
.overview-box .custom .content .sub-title {
  display: block;
  margin-bottom: 10px;
  text-transform: uppercase;
  color: #ff0008;
  font-size: 12.5px;
  /* font-family: raleway, sans-serif; */
  font-family: Montserrat !important;
  font-weight: 700;
}
.overview-box .custom .content.left-content {
  margin-left: auto;
  padding-right: 50px;
  padding-left: 0;
}
.overview-box .custom .content h2 {
  margin-bottom: 0;
  font-size: 40px;
  font-weight: 600;
}
.overview-box .custom .content .bar {
  height: 5px;
  width: 90px;
  background: #cdf1d8;
  margin: 20px 0 25px;
  position: relative;
  border-radius: 30px;
}
.overview-box .custom .content .bar::before {
  content: "";
  position: absolute;
  left: 0;
  top: -2.7px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: #44ce6f;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: MOVE-BG;
  animation-name: MOVE-BG;
}
.overview-box .custom .content .services-list {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center !important;
  -webkit-box-align: center !important;
  align-items: center !important;
  padding-left: 0;
  list-style-type: none;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 0;
  margin-top: -5px;
}
.overview-box .custom .content .services-list li {
  -ms-flex: 0 0 50%;
  -webkit-box-flex: 0;
  flex: 0 0 50%;
  max-width: 50%;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
}
.overview-box .custom .content .services-list li span {
  display: block;
  position: relative;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  color: #6084a4;
  z-index: 1;
  border-radius: 5px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 35px;
  font-size: 14px;
  font-weight: 400;
}
.overview-box .custom .content .services-list li span i {
  position: absolute;
  left: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #ff0008;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.overview-box .custom .content .services-list li span i::before {
  font-size: 12px;
}
.overview-box .custom .content .services-list li span::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 0;
  height: 100%;
  border-radius: 5px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ff0008),
    to(#ff6a00)
  );
  background: linear-gradient(90deg, #ff0008 0%, #ff6a00 100%);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.overview-box .custom .content .services-list li span:hover {
  color: #fff;
}
.overview-box .custom .content .services-list li span:hover i {
  color: #fff;
}
.overview-box .custom .content .services-list li span:hover::before {
  width: 100%;
}
.overview-box .custom .content .features-list {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center !important;
  -webkit-box-align: center !important;
  align-items: center !important;
  padding-left: 0;
  list-style-type: none;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 0;
  margin-top: -5px;
}
.overview-box .custom .content .features-list li {
  -ms-flex: 0 0 50%;
  -webkit-box-flex: 0;
  flex: 0 0 50%;
  max-width: 50%;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
}
.overview-box .custom .content .features-list li span {
  display: block;
  color: #6084a4;
  position: relative;
  padding-left: 31px;
  font-size: 14px;
  font-weight: 400;
}
.overview-box .custom .content .features-list li span i {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #fff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  width: 22px;
  height: 20px;
  text-align: center;
  line-height: 18px;
  background: linear-gradient(135deg, #e7322f 0%, #ff0008 100%);
  border-radius: 3px;
}
.overview-box .custom .content .features-list li span i::before {
  font-size: 10px;
}
.overview-box .custom .content .btn {
  margin-top: 30px;
}

@-webkit-keyframes MOVE-BG {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(88px);
    transform: translateX(88px);
  }
}
@keyframes MOVE-BG {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(88px);
    transform: translateX(88px);
  }
}
@-webkit-keyframes rotateMe {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotateMe {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.comparisons-table {
  background-color: #fff;
  -webkit-box-shadow: 0 -10px 30px rgba(0, 0, 0, 0.05);
  box-shadow: 0 -10px 30px rgba(0, 0, 0, 0.05);
}
.comparisons-table table {
  margin-bottom: 0;
  text-align: center;
}
.comparisons-table table thead tr {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ff0008),
    to(#ff6a00)
  );
  background: linear-gradient(90deg, #ff0008 0%, #ff6a00 100%);
}
.comparisons-table table thead th {
  vertical-align: middle;
  border: none;
  color: #fff;
  padding: 16px 20px;
  /* font-family: raleway, sans-serif; */
  font-family: Montserrat !important;
  font-size: 19px;
  font-weight: 600;
}
.comparisons-table table thead th:nth-child(1) {
  text-align: left;
  padding-left: 50px;
}
.comparisons-table table tbody td {
  vertical-align: middle;
  color: #0e314c;
  padding: 16px 20px;
  border-color: #eee;
  /* font-family: raleway, sans-serif; */
  font-family: Montserrat !important;
  font-size: 15px;
  font-weight: 400;
}
.comparisons-table table tbody td:nth-child(1) {
  text-align: left;
  padding-left: 50px;
}
.comparisons-table table tbody td i {
  width: 20px;
  height: 20px;
  line-height: 17px;
  border-radius: 100%;
  background-color: #ff0008;
  color: #fff;
  display: block;
  margin: 0 auto;
}
.comparisons-table table tbody td i::before {
  font-size: 8px;
}
.comparisons-table table tbody tr:nth-child(1) td i,
.comparisons-table table tbody tr:nth-child(8) td i {
  background-color: #facd60;
}
.comparisons-table table tbody tr:nth-child(2) td i,
.comparisons-table table tbody tr:nth-child(9) td i {
  background-color: #44cd6f;
}
.comparisons-table table tbody tr:nth-child(3) td i,
.comparisons-table table tbody tr:nth-child(10) td i {
  background-color: #fd6c28;
}
.comparisons-table table tbody tr:nth-child(5) td i,
.comparisons-table table tbody tr:nth-child(12) td i {
  background-color: #1ac0c6;
}
.comparisons-table table tbody tr:nth-child(6) td i,
.comparisons-table table tbody tr:nth-child(13) td i {
  background-color: #f45271;
}
.comparisons-table table tbody tr:nth-child(7) td i,
.comparisons-table table tbody tr:nth-child(14) td i {
  background-color: #fd6d27;
}
.features-area {
  padding-bottom: 50px;
}
.single-features-box {
  margin-bottom: 30px;
  background-color: #fff;
  border-radius: 5px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  position: relative;
  z-index: 1;
  padding: 30px;
}
.single-features-box .icon {
  width: 65px;
  height: 65px;
  text-align: center;
  line-height: 65px;
  background-color: rgba(231, 70, 69, 0.2);
  border-radius: 50%;
  color: #ff0008;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  margin-bottom: 18px;
}
.single-features-box .icon i::before {
  font-size: 30px;
}
.single-features-box .icon.bg-f78acb {
  background-color: rgba(247, 138, 203, 0.3);
  color: #f78acb;
}
.single-features-box .icon.bg-cdf1d8 {
  background-color: #cdf1d8;
  color: #44ce6f;
}
.single-features-box .icon.bg-c679e3 {
  color: #c679e3;
  background: #edc3fc;
}
.single-features-box .icon.bg-eb6b3d {
  color: #eb6b3d;
  background: rgba(235, 107, 61, 0.3);
}
.single-features-box h3 {
  margin-bottom: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 22px;
  font-weight: 600;
}
.single-features-box h3 a {
  display: inline-block;
}
.single-features-box p {
  font-size: 14px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  margin-top: 10px;
  margin-bottom: 0;
}
.single-features-box::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 0;
  height: 100%;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ff0008),
    to(#ff6a00)
  );
  background: linear-gradient(90deg, #ff0008 0%, #ff6a00 100%);
  border-radius: 5px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.single-features-box:hover {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}
.single-features-box:hover::before {
  width: 100%;
}
.single-features-box:hover .icon {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
  color: #ff0008;
  background-color: #fff;
}
.single-features-box:hover h3 {
  color: #fff;
}
.single-features-box:hover h3 a {
  color: #fff;
}
.single-features-box:hover p {
  color: #fff;
}
.features-box-list {
  margin-left: auto;
  max-width: 465px;
}
.features-box-list .col-lg-12:last-child .features-box {
  margin-bottom: 0;
}
.features-box {
  margin-bottom: 20px;
  background-color: #fff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  position: relative;
  z-index: 1;
  padding-top: 35px;
  padding-bottom: 35px;
  padding-right: 25px;
  padding-left: 110px;
}
.features-box .icon {
  width: 65px;
  height: 65px;
  text-align: center;
  line-height: 65px;
  background-color: rgba(231, 70, 69, 0.2);
  border-radius: 50%;
  color: #ff0008;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  position: absolute;
  left: 25px;
  top: 35px;
}
.features-box .icon i::before {
  font-size: 30px;
}
.features-box .icon.bg-f78acb {
  background-color: rgba(247, 138, 203, 0.3);
  color: #f78acb;
}
.features-box .icon.bg-cdf1d8 {
  background-color: #cdf1d8;
  color: #44ce6f;
}
.features-box .icon.bg-c679e3 {
  color: #c679e3;
  background: #edc3fc;
}
.features-box .icon.bg-eb6b3d {
  color: #eb6b3d;
  background: rgba(235, 107, 61, 0.3);
}
.features-box h3 {
  margin-bottom: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 22px;
  font-weight: 600;
}
.features-box h3 a {
  display: inline-block;
}
.features-box p {
  font-size: 14px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  margin-top: 10px;
  margin-bottom: 0;
}
.features-box::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 2px;
  height: 100%;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ff0008),
    to(#ff6a00)
  );
  background: linear-gradient(90deg, #ff0008 0%, #ff6a00 100%);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.features-box:hover {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}
.features-box:hover::before {
  width: 100%;
}
.features-box:hover .icon {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
  color: #ff0008;
  background-color: #fff;
}
.features-box:hover h3 {
  color: #fff;
}
.features-box:hover h3 a {
  color: #fff;
}
.features-box:hover p {
  color: #fff;
}
.features-image {
  text-align: center;
}
.how-it-works-area {
  padding-bottom: 40px;
}
.single-how-it-works {
  text-align: center;
  background-color: #fff;
  margin-bottom: 30px;
  -webkit-box-shadow: 0 7px 20px rgba(0, 0, 0, 0.03);
  box-shadow: 0 7px 20px rgba(0, 0, 0, 0.03);
  padding: 36px;
  border-radius: 5px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.single-how-it-works img {
  display: inline-block;
  margin-bottom: 30px;
}
.single-how-it-works h3 {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 600;
}
.single-how-it-works p {
  margin-top: 12px;
  margin-bottom: 0;
}
.single-how-it-works:hover {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
  -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}
.team-area {
  padding-bottom: 40px;
}
.single-team-member {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center !important;
  -webkit-box-align: center !important;
  align-items: center !important;
  margin-right: -10px;
  margin-left: -10px;
  margin-bottom: 30px;
}
.single-team-member .member-image {
  -ms-flex: 0 0 33.333333%;
  -webkit-box-flex: 0;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  position: relative;
  padding-left: 10px;
  padding-right: 10px;
}
.single-team-member .member-image img {
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border-radius: 3px;
}
.single-team-member .member-image .social {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0 auto;
  bottom: 0;
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.single-team-member .member-image .social li {
  display: inline-block;
}
.single-team-member .member-image .social li a {
  margin: 0 2px;
  display: inline-block;
}
.single-team-member .member-image .social li a i {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  background-color: #44ce6f;
  color: #fff;
  font-size: 13px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.single-team-member .member-image .social li a i.fa-facebook-f {
  background: #3b5998;
}
.single-team-member .member-image .social li a i.fa-linkedin-in {
  background: #0077b5;
}
.single-team-member .member-image .social li a i:hover {
  background-color: #44ce6f;
  color: #fff;
}
.single-team-member .member-content {
  -ms-flex: 0 0 66.666667%;
  -webkit-box-flex: 0;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
  padding-left: 10px;
  padding-right: 10px;
}
.single-team-member .member-content h3 {
  margin-bottom: 0;
  font-size: 22px;
  font-weight: 700;
}
.single-team-member .member-content span {
  display: block;
  font-size: 13px;
  color: #ff0008;
  margin-top: 8px;
}
.single-team-member .member-content p {
  font-size: 14px;
  margin-bottom: 0;
  margin-top: 10px;
}
.single-team-member:hover .member-image .social {
  bottom: 15px;
  opacity: 1;
  visibility: visible;
}
.invoicing-area {
  overflow: hidden;
}
.invoicing-content {
  margin-left: auto;
  max-width: 625px;
}
.invoicing-content h2 {
  margin-bottom: 0;
  font-size: 40px;
  font-weight: 600;
}
.invoicing-content .bar {
  height: 5px;
  width: 90px;
  background: #cdf1d8;
  margin: 20px 0 25px;
  position: relative;
  border-radius: 30px;
}
.invoicing-content .bar::before {
  content: "";
  position: absolute;
  left: 0;
  top: -2.7px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: #44ce6f;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: MOVE-BG;
  animation-name: MOVE-BG;
}
.invoicing-content p {
  margin-bottom: 0;
}
.invoicing-content .btn {
  margin-top: 20px;
}
.invoicing-image {
  text-align: center;
  position: relative;
  z-index: 1;
}
.invoicing-image .main-image img:nth-child(2) {
  position: absolute;
  left: 40px;
  top: 15px;
}
.invoicing-image .main-image img:nth-child(3) {
  position: absolute;
  left: 40px;
  top: 170px;
}
.invoicing-image .main-image img:nth-child(4) {
  position: absolute;
  right: 40px;
  top: 60px;
}
.invoicing-image .main-mobile-image {
  display: none;
}
.invoicing-image .circle-image img {
  position: absolute;
  z-index: -1;
}
.invoicing-image .circle-image img:nth-child(1) {
  top: -30px;
  left: 50px;
  -webkit-animation: moveLeftBounce 5s linear infinite;
  animation: moveLeftBounce 5s linear infinite;
}
.invoicing-image .circle-image img:nth-child(2) {
  right: 50px;
  bottom: -30px;
  -webkit-animation: moveBounce 5s linear infinite;
  animation: moveBounce 5s linear infinite;
}
@-webkit-keyframes moveBounce {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@keyframes moveBounce {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@-webkit-keyframes moveLeftBounce {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  50% {
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}
@keyframes moveLeftBounce {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  50% {
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}
.information-area {
  padding-top: 70px;
}
.information-area .col-lg-3:last-child .single-information-box {
  padding-left: 0;
}
.single-information-box {
  position: relative;
  padding-left: 55px;
}
.single-information-box .icon {
  width: 42px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  border-radius: 50%;
  background-color: #ff0008;
  color: #fff;
  position: absolute;
  left: 0;
  top: 0;
}
.single-information-box .icon i::before {
  font-size: 20px;
}
.single-information-box h3 {
  margin-bottom: 0;
  font-size: 17px;
  font-weight: 700;
}
.single-information-box p {
  line-height: initial;
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 0;
}
.single-information-box .btn-box .app-store-btn {
  border-radius: 3px;
  display: inline-block;
  position: relative;
  z-index: 1;
  color: #fff;
  padding: 10px 15px 10px 35px;
  font-size: 11px;
}
.single-information-box .btn-box .app-store-btn i {
  position: absolute;
  left: 8px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.single-information-box .btn-box .app-store-btn i::before {
  font-size: 20px;
}
.single-information-box .btn-box .app-store-btn span {
  display: block;
  font-size: 15px;
  font-weight: 500;
}
.single-information-box .btn-box .app-store-btn::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  z-index: -1;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ff0008),
    to(#ff6a00)
  );
  background: linear-gradient(90deg, #ff0008 0%, #ff6a00 100%);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.single-information-box .btn-box .app-store-btn::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  z-index: -1;
  background: linear-gradient(135deg, #e7322f 0%, #ff0008 100%);
  -webkit-transition: 0.5s;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}
.single-information-box .btn-box .app-store-btn:hover {
  color: #fff;
}
.single-information-box .btn-box .app-store-btn:hover::after {
  opacity: 1;
  visibility: visible;
}
.single-information-box .btn-box .app-store-btn:hover::before {
  opacity: 0;
  visibility: hidden;
}
.single-information-box .btn-box .play-store-btn {
  margin-left: 5px;
  border-radius: 3px;
  display: inline-block;
  position: relative;
  z-index: 1;
  color: #fff;
  padding: 10px 15px 10px 35px;
  font-size: 11px;
}
.single-information-box .btn-box .play-store-btn i {
  position: absolute;
  left: 8px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.single-information-box .btn-box .play-store-btn i::before {
  font-size: 20px;
}
.single-information-box .btn-box .play-store-btn span {
  display: block;
  font-size: 15px;
  font-weight: 500;
}
.single-information-box .btn-box .play-store-btn::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  z-index: -1;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ff0008),
    to(#ff6a00)
  );
  background: linear-gradient(90deg, #ff0008 0%, #ff6a00 100%);
  -webkit-transition: 0.5s;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}
.single-information-box .btn-box .play-store-btn::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  z-index: -1;
  background: linear-gradient(135deg, #e7322f 0%, #ff0008 100%);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.single-information-box .btn-box .play-store-btn:hover {
  color: #fff;
}
.single-information-box .btn-box .play-store-btn:hover::after {
  opacity: 0;
  visibility: hidden;
}
.single-information-box .btn-box .play-store-btn:hover::before {
  opacity: 1;
  visibility: visible;
}
.pricing-area {
  padding-bottom: 40px;
}
.single-pricing-box {
  background-color: #fff;
  margin-bottom: 30px;
  -webkit-box-shadow: 0 11px 60px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 11px 60px 0 rgba(0, 0, 0, 0.1);
  padding: 30px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 5px;
}
.single-pricing-box .pricing-header h3 {
  margin-bottom: 0;
  font-size: 22px;
  font-weight: 600;
}
.single-pricing-box .pricing-header p {
  font-size: 14px;
  margin-top: 8px;
  margin-bottom: 0;
}
.single-pricing-box .price {
  font-size: 50px;
  font-weight: 400;
  /* font-family: raleway, sans-serif; */
  font-family: Montserrat !important;
  margin-bottom: 10px;
}
.single-pricing-box .price span {
  display: inline-block;
  margin-left: -10px;
  font-size: 18px;
  font-weight: 300;
}
.single-pricing-box .buy-btn {
  margin-bottom: 25px;
}
.single-pricing-box .buy-btn .btn {
  padding: 13px 30px;
  font-size: 13px;
}
.single-pricing-box .buy-btn .btn-primary {
  border-radius: 30px;
}
.single-pricing-box .buy-btn .btn-primary::after {
  border-radius: 30px;
}
.single-pricing-box .buy-btn .btn-primary::before {
  background: -webkit-gradient(
    linear,
    left bottom,
    right top,
    from(#a3a3a3),
    color-stop(#9a9a9a),
    color-stop(#909090),
    color-stop(#878787),
    to(#7e7e7e)
  );
  background: linear-gradient(
    to right top,
    #a3a3a3,
    #9a9a9a,
    #909090,
    #878787,
    #7e7e7e
  );
  border-radius: 30px;
}
.single-pricing-box .pricing-features {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.single-pricing-box .pricing-features li {
  margin-bottom: 12px;
  color: #6084a4;
  position: relative;
  padding-left: 19px;
  font-weight: 300;
}
.single-pricing-box .pricing-features li:last-child {
  margin-bottom: 0;
}
.single-pricing-box .pricing-features li i {
  color: #ff0008;
  font-size: 12px;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.single-pricing-box .pricing-features li i::before {
  font-size: 12px;
}
.single-pricing-box:hover .buy-btn .btn-primary::after {
  opacity: 1;
  visibility: visible;
}
.funfacts-area {
  position: relative;
  z-index: 1;
}
.funfacts-area .map-bg {
  position: absolute;
  z-index: -1;
  top: 120px;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0 auto;
}
.funfacts-area .row {
  padding-left: 100px;
  padding-right: 100px;
}
.funfact {
  text-align: center;
}
.funfact h3 {
  color: #ff0008;
  margin-bottom: 2px;
  font-size: 35px;
  font-weight: 600;
}
.funfact h3 .odometer {
  position: relative;
  top: -2px;
}
.funfact p {
  line-height: initial;
  margin-bottom: 0;
}
.contact-cta-box {
  margin: 70px auto 0;
  max-width: 600px;
  border: 1px dashed #ebebeb;
  padding: 30px 210px 30px 30px;
  border-radius: 5px;
  position: relative;
}
.contact-cta-box h3 {
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 22px;
}
.contact-cta-box p {
  margin-bottom: 0;
  line-height: initial;
}
.contact-cta-box .btn {
  position: absolute;
  right: 30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.feedback-slides {
  position: relative;
  max-width: 750px;
  margin: 0 auto;
}
.feedback-slides .client-feedback {
  position: relative;
}
.feedback-slides .client-feedback .single-feedback {
  background: #fff;
  border-radius: 5px;
  margin-bottom: 60px;
  position: relative;
  padding-left: 170px;
  padding-right: 40px;
  padding-top: 40px;
  padding-bottom: 40px;
}
.feedback-slides .client-feedback .single-feedback .client-img {
  position: absolute;
  left: 40px;
  top: 40px;
}
.feedback-slides .client-feedback .single-feedback .client-img img {
  border-radius: 50%;
  border: 2px solid #44ce6f;
  padding: 4px;
}
.feedback-slides .client-feedback .single-feedback h3 {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 18px;
}
.feedback-slides .client-feedback .single-feedback span {
  display: block;
  color: #ff0008;
  margin-top: 5px;
  margin-bottom: 12px;
  font-weight: 300;
  /* font-family: raleway, sans-serif; */
  font-family: Montserrat !important;
  font-size: 14px;
}
.feedback-slides .client-feedback .single-feedback::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -25px;
  width: 50px;
  height: 50px;
  background: #fff;
  right: 0;
  margin: 0 auto;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.feedback-slides .client-thumbnails {
  position: relative;
  margin: 0 85px;
}
.feedback-slides .client-thumbnails .item .img-fill {
  cursor: pointer;
  position: relative;
  text-align: center;
}
.feedback-slides .client-thumbnails .item .img-fill img {
  opacity: 0.4;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  display: inline-block;
  position: relative;
  border: 2px solid #44ce6f;
  border-radius: 50%;
  padding: 3px;
  width: 85px;
}
.feedback-slides .client-thumbnails .item:hover .img-fill img,
.feedback-slides .client-thumbnails .item.slick-center .img-fill img {
  opacity: 1;
}
.feedback-slides .client-thumbnails .next-arrow,
.feedback-slides .client-thumbnails .prev-arrow {
  position: absolute;
  width: 40px;
  height: 40px;
  cursor: pointer;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background: 0 0;
  color: #5e5e5e;
  border: 1px solid #5e5e5e;
  z-index: 1;
  border-radius: 50%;
  line-height: 40px;
  outline: 0 !important;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.feedback-slides .client-thumbnails .next-arrow::before,
.feedback-slides .client-thumbnails .next-arrow::before,
.feedback-slides .client-thumbnails .prev-arrow::before,
.feedback-slides .client-thumbnails .prev-arrow::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ff0008),
    to(#ff6a00)
  );
  background: linear-gradient(90deg, #ff0008 0%, #ff6a00 100%);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 50%;
  z-index: -1;
}
.feedback-slides .client-thumbnails .next-arrow:hover,
.feedback-slides .client-thumbnails .prev-arrow:hover {
  color: #fff;
  border-color: #ff0008;
}
.feedback-slides .client-thumbnails .next-arrow:hover::before,
.feedback-slides .client-thumbnails .prev-arrow:hover::before {
  opacity: 1;
  visibility: visible;
}
.feedback-slides .client-thumbnails .next-arrow {
  right: -20px;
}
.feedback-slides .client-thumbnails .prev-arrow {
  left: -20px;
}
.ready-to-talk {
  text-align: center;
  position: relative;
  background: linear-gradient(135deg, #e7322f 0%, #ff0008 100%);
  padding-top: 70px;
  padding-bottom: 145px;
}
.ready-to-talk-content h3 {
  color: #fff;
  text-transform: capitalize;
  margin-bottom: 10px;
  font-size: 40px;
  font-weight: 600;
}
.ready-to-talk-content p {
  color: #fff;
  margin-bottom: 20px;
}
.ready-to-talk-content .btn-primary::after {
  background: #fff;
}
.ready-to-talk-content .btn-primary:hover,
.ready-to-talk-content .btn-primary:focus {
  color: #ff0008 !important;
}
.ready-to-talk-content span a {
  margin-left: 15px;
  color: #fff;
  text-decoration: underline;
}
.partner-area {
  text-align: center;
  position: relative;
  margin-top: -120px;
  background: 0 0;
}
.partner-area h3 {
  color: #fff;
  margin-bottom: 0;
  font-size: 22px;
  font-weight: 600;
}
.partner-inner {
  background: #fff;
  -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  margin-top: 20px;
  text-align: center;
  padding-top: 5px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
}
.partner-inner a {
  display: block;
  margin-top: 25px;
  position: relative;
}
.partner-inner a img {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.partner-inner a img:nth-child(2) {
  position: absolute;
  top: 15px;
  left: 0;
  right: 0;
  margin: 0 auto;
  opacity: 0;
  visibility: hidden;
}
.partner-inner a:hover img:nth-child(1) {
  opacity: 0;
  visibility: hidden;
}
.partner-inner a:hover img:nth-child(2) {
  opacity: 1;
  top: 0;
  visibility: visible;
}
.app-download-area {
  padding-top: 95px;
  padding-bottom: 50px;
}
.app-image {
  position: relative;
  z-index: 1;
  text-align: left;
}
.app-image .main-image img:nth-child(2) {
  position: absolute;
  right: 20px;
  top: 0;
}
.app-image .main-mobile-image {
  display: none;
}
.app-image .circle-img {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-55%);
  transform: translateY(-55%);
  z-index: -1;
  right: 0;
  margin: 0 auto;
}
.app-image .circle-img img {
  -webkit-animation-name: rotateMe;
  animation-name: rotateMe;
  -webkit-animation-duration: 35s;
  animation-duration: 35s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}
.app-download-content {
  padding-left: 20px;
}
.app-download-content h2 {
  margin-bottom: 0;
  font-size: 40px;
  font-weight: 600;
}
.app-download-content .bar {
  height: 5px;
  width: 90px;
  background: #cdf1d8;
  margin: 20px 0 25px;
  position: relative;
  border-radius: 30px;
}
.app-download-content .bar::before {
  content: "";
  position: absolute;
  left: 0;
  top: -2.7px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: #44ce6f;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: MOVE-BG;
  animation-name: MOVE-BG;
}
.app-download-content p {
  margin-bottom: 0;
}
.app-download-content .btn-box {
  margin-top: 25px;
}
.app-download-content .btn-box .app-store-btn {
  border-radius: 3px;
  display: inline-block;
  position: relative;
  z-index: 1;
  color: #fff;
  padding: 12px 25px 12px 60px;
  font-size: 12px;
}
.app-download-content .btn-box .app-store-btn i {
  position: absolute;
  left: 16px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.app-download-content .btn-box .app-store-btn i::before {
  font-size: 35px;
}
.app-download-content .btn-box .app-store-btn span {
  display: block;
  font-size: 18px;
  font-weight: 500;
}
.app-download-content .btn-box .app-store-btn::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  z-index: -1;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ff0008),
    to(#ff6a00)
  );
  background: linear-gradient(90deg, #ff0008 0%, #ff6a00 100%);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.app-download-content .btn-box .app-store-btn::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  z-index: -1;
  background: linear-gradient(135deg, #e7322f 0%, #ff0008 100%);
  -webkit-transition: 0.5s;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}
.app-download-content .btn-box .app-store-btn:hover {
  color: #fff;
}
.app-download-content .btn-box .app-store-btn:hover::after {
  opacity: 1;
  visibility: visible;
}
.app-download-content .btn-box .app-store-btn:hover::before {
  opacity: 0;
  visibility: hidden;
}
.app-download-content .btn-box .play-store-btn {
  margin-left: 12px;
  border-radius: 3px;
  display: inline-block;
  position: relative;
  z-index: 1;
  color: #fff;
  padding: 12px 25px 12px 60px;
  font-size: 12px;
}
.app-download-content .btn-box .play-store-btn i {
  position: absolute;
  left: 16px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.app-download-content .btn-box .play-store-btn i::before {
  font-size: 35px;
}
.app-download-content .btn-box .play-store-btn span {
  display: block;
  font-size: 18px;
  font-weight: 500;
}
.app-download-content .btn-box .play-store-btn::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  z-index: -1;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ff0008),
    to(#ff6a00)
  );
  background: linear-gradient(90deg, #ff0008 0%, #ff6a00 100%);
  -webkit-transition: 0.5s;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}
.app-download-content .btn-box .play-store-btn::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  z-index: -1;
  background: linear-gradient(135deg, #e7322f 0%, #ff0008 100%);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.app-download-content .btn-box .play-store-btn:hover {
  color: #fff;
}
.app-download-content .btn-box .play-store-btn:hover::after {
  opacity: 0;
  visibility: hidden;
}
.app-download-content .btn-box .play-store-btn:hover::before {
  opacity: 1;
  visibility: visible;
}
.account-create-area {
  position: relative;
  z-index: 1;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ff0008),
    to(#ff6a00)
  );
  background: linear-gradient(90deg, #ff0008 0%, #ff6a00 100%);
  padding-top: 100px;
  padding-bottom: 100px;
}
.account-create-area::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.2;
  height: 100%;
  z-index: -1;
  background-image: url(../img/bg_lines.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 200%;
}
.account-create-content {
  text-align: center;
}
.account-create-content h2 {
  color: #fff;
  max-width: 530px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
  font-size: 40px;
  font-weight: 600;
}
.account-create-content p {
  color: #fff;
  line-height: initial;
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 0;
}
.account-create-content .btn-primary {
  margin-top: 30px;
  padding: 16px 30px;
}
.account-create-content .btn-primary::before {
  background: linear-gradient(135deg, #e7322f 0%, #ff0008 100%);
}
.account-create-content .btn-primary::after {
  background: #fff;
}
.account-create-content .btn-primary:hover,
.account-create-content .btn-primary:focus {
  color: #0e314c !important;
}
.blog-area {
  padding-bottom: 40px;
}
.blog-area .pagination-area {
  margin-bottom: 30px;
}
.single-blog-post {
  background: #f7fafd;
  position: relative;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  margin-bottom: 30px;
}
.single-blog-post .blog-image {
  position: relative;
}
.single-blog-post .blog-image a {
  display: block;
}
.single-blog-post .blog-image .date {
  position: absolute;
  left: 20px;
  bottom: -20px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ff0008),
    to(#ff6a00)
  );
  background: linear-gradient(90deg, #ff0008 0%, #ff6a00 100%);
  color: #fff;
  padding: 10px 16px 8px;
  border-radius: 50px;
  /* font-family: raleway, sans-serif; */
  font-family: Montserrat !important;
  font-size: 13px;
}
.single-blog-post .blog-image .date i {
  margin-right: 2px;
}
.single-blog-post .blog-post-content {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  padding-top: 40px;
  padding-left: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
}
.single-blog-post .blog-post-content h3 {
  line-height: 30px;
  margin-bottom: 0;
  font-size: 22px;
  font-weight: 600;
}
.single-blog-post .blog-post-content span {
  display: block;
  color: #0e314c;
  font-size: 14.5px;
  margin-top: 13px;
  margin-bottom: 12px;
}
.single-blog-post .blog-post-content span a {
  color: #6084a4;
}
.single-blog-post .blog-post-content span a:hover {
  color: #ff0008;
}
.single-blog-post .blog-post-content .read-more-btn {
  font-size: 14.5px;
  font-weight: 500;
  /* font-family: raleway, sans-serif; */
  font-family: Montserrat !important;
}
.single-blog-post .blog-post-content .read-more-btn i {
  font-size: 12px;
  margin-left: 2px;
}
.single-blog-post:hover {
  -webkit-transform: translateY(-7px);
  transform: translateY(-7px);
  -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  background-color: #fff;
}
.blog-details .article-content {
  margin-top: 30px;
}
.blog-details .article-content .entry-meta {
  margin-bottom: 15px;
}
.blog-details .article-content .entry-meta ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.blog-details .article-content .entry-meta ul li {
  position: relative;
  display: inline-block;
  color: #0e314c;
  margin-right: 21px;
  font-weight: 300;
}
.blog-details .article-content .entry-meta ul li span {
  display: inline-block;
  color: #0e314c;
}
.blog-details .article-content .entry-meta ul li a {
  display: inline-block;
  color: #6084a4;
}
.blog-details .article-content .entry-meta ul li a:hover {
  color: #ff0008;
}
.blog-details .article-content .entry-meta ul li i {
  color: #ff0008;
  margin-right: 2px;
}
.blog-details .article-content .entry-meta ul li::before {
  content: "";
  position: absolute;
  top: 11px;
  right: -15px;
  width: 6px;
  height: 1px;
  background: #ff0008;
}
.blog-details .article-content .entry-meta ul li:last-child {
  margin-right: 0;
}
.blog-details .article-content .entry-meta ul li:last-child::before {
  display: none;
}
.blog-details .article-content h3 {
  margin-bottom: 15px;
  font-size: 22px;
  font-weight: 600;
}
.blog-details .article-content p {
  line-height: 1.8;
}
.blog-details .article-content .wp-block-gallery.columns-3 {
  padding-left: 0;
  list-style-type: none;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
  margin-bottom: 30px;
  margin-top: 30px;
}
.blog-details .article-content .wp-block-gallery.columns-3 li {
  -ms-flex: 0 0 33.3333%;
  -webkit-box-flex: 0;
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
  padding-right: 10px;
  padding-left: 10px;
}
.blog-details .article-content .wp-block-gallery.columns-3 li figure {
  margin-bottom: 0;
}
.blog-details .article-content .article-features-list {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 15px;
  margin-left: 20px;
}
.blog-details .article-content .article-features-list li {
  margin-bottom: 12px;
  color: #6084a4;
  position: relative;
  padding-left: 15px;
  font-weight: 300;
}
.blog-details .article-content .article-features-list li::before {
  background: #ff0008;
  height: 7px;
  width: 7px;
  content: "";
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  border-radius: 50%;
  position: absolute;
}
.blog-details .article-content .article-features-list li:last-child {
  margin-bottom: 0;
}
.blog-details .article-footer {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-top: 15px;
  padding-bottom: 15px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  margin-top: 30px;
}
.blog-details .article-footer .article-tags {
  -ms-flex: 0 0 50%;
  -webkit-box-flex: 0;
  flex: 0 0 50%;
  max-width: 50%;
}
.blog-details .article-footer .article-tags span {
  display: inline-block;
  color: #0e314c;
  font-size: 20px;
  margin-right: 5px;
  position: relative;
  top: 2px;
}
.blog-details .article-footer .article-tags a {
  display: inline-block;
  color: #6084a4;
  font-weight: 300;
}
.blog-details .article-footer .article-tags a:hover {
  color: #ff0008;
}
.blog-details .article-footer .article-share {
  -ms-flex: 0 0 50%;
  -webkit-box-flex: 0;
  flex: 0 0 50%;
  max-width: 50%;
}
.blog-details .article-footer .article-share .social {
  padding-left: 0;
  list-style-type: none;
  text-align: right;
  margin-bottom: 0;
}
.blog-details .article-footer .article-share .social li {
  display: inline-block;
}
.blog-details .article-footer .article-share .social li a {
  color: #0e314c;
  background-color: #f7f7f7;
  width: 33px;
  height: 33px;
  line-height: 35px;
  text-align: center;
  border-radius: 50%;
  font-size: 13px;
  display: inline-block;
}
.blog-details .article-footer .article-share .social li a:hover {
  color: #fff;
  background-color: #ff0008;
}
blockquote,
.blockquote {
  overflow: hidden;
  background-color: #fafafa;
  padding: 50px !important;
  position: relative;
  text-align: center;
  z-index: 1;
  /* font-family: raleway, sans-serif; */
  font-family: Montserrat !important;
  margin-bottom: 20px;
  margin-top: 20px;
}
blockquote p,
.blockquote p {
  color: #0e314c;
  line-height: 1.6;
  margin-bottom: 0;
  font-style: italic;
  font-weight: 600;
  font-size: 20px !important;
}
blockquote cite,
.blockquote cite {
  display: none;
}
blockquote::before,
.blockquote::before {
  color: #efefef;
  content: "\f10d";
  position: absolute;
  left: 50px;
  top: -60px;
  z-index: -1;
  /* font-family: "font awesome 5 free"; */
  font-family: Montserrat !important;
  font-size: 140px;
  font-weight: 900;
}
.comments-area {
  margin-top: 28px;
}
.comments-area .comments-title {
  line-height: initial;
  margin-bottom: 25px;
  font-size: 22px;
  font-weight: 600;
}
.comments-area ol,
.comments-area ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.comments-area .comment-list {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.comments-area .children {
  margin-left: 20px;
}
.comments-area .comment-body {
  border-bottom: 1px solid #eee;
  padding-left: 65px;
  color: #0e314c;
  margin-bottom: 20px;
  padding-bottom: 20px;
}
.comments-area .comment-body .reply {
  margin-top: 15px;
}
.comments-area .comment-body .reply a {
  border: 1px solid #eee;
  color: #6084a4;
  display: inline-block;
  padding: 5px 20px;
  border-radius: 30px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  /* font-family: raleway, sans-serif; */
  font-family: Montserrat !important;
}
.comments-area .comment-body .reply a:hover {
  color: #fff;
  background-color: #ff0008;
  border-color: #ff0008;
}
.comments-area .comment-meta {
  margin-bottom: 0.8em;
}
.comments-area .comment-author {
  font-size: 16px;
  margin-bottom: 0.4em;
  position: relative;
  z-index: 2;
}
.comments-area .comment-author .avatar {
  height: 50px;
  left: -65px;
  position: absolute;
  width: 50px;
}
.comments-area .comment-author .fn {
  font-weight: 600;
  /* font-family: raleway, sans-serif; */
  font-family: Montserrat !important;
}
.comments-area .comment-author .says {
  display: none;
}
.comments-area .comment-metadata {
  color: #6084a4;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 400;
}
.comments-area .comment-metadata a {
  color: #6084a4;
}
.comments-area .comment-metadata a:hover {
  color: #ff0008;
}
.comments-area .comment-content p {
  font-size: 14px;
}
.comments-area .comment-respond .comment-reply-title {
  margin-bottom: 0;
  font-size: 22px;
  font-weight: 600;
}
.comments-area
  .comment-respond
  .comment-reply-title
  #cancel-comment-reply-link {
  font-size: 15px;
  display: inline-block;
}
.comments-area .comment-respond .comment-form {
  overflow: hidden;
}
.comments-area .comment-respond .comment-notes {
  font-size: 14px;
  margin-bottom: 0;
  margin-top: 8px;
}
.comments-area .comment-respond .comment-notes .required {
  color: red;
}
.comments-area .comment-respond .comment-form-comment {
  margin-top: 15px;
  float: left;
  width: 100%;
}
.comments-area .comment-respond label {
  display: block;
  margin-bottom: 5px;
  color: #0e314c;
  font-weight: 400;
  /* font-family: raleway, sans-serif; */
  font-family: Montserrat !important;
}
.comments-area .comment-respond input[type="date"],
.comments-area .comment-respond input[type="time"],
.comments-area .comment-respond input[type="datetime-local"],
.comments-area .comment-respond input[type="week"],
.comments-area .comment-respond input[type="month"],
.comments-area .comment-respond input[type="text"],
.comments-area .comment-respond input[type="email"],
.comments-area .comment-respond input[type="url"],
.comments-area .comment-respond input[type="password"],
.comments-area .comment-respond input[type="search"],
.comments-area .comment-respond input[type="tel"],
.comments-area .comment-respond input[type="number"],
.comments-area .comment-respond textarea {
  display: block;
  width: 100%;
  background-color: #fff;
  border: 1px solid #eee;
  padding: 0.625em 0.7375em;
  outline: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.comments-area .comment-respond input[type="date"]:focus,
.comments-area .comment-respond input[type="time"]:focus,
.comments-area .comment-respond input[type="datetime-local"]:focus,
.comments-area .comment-respond input[type="week"]:focus,
.comments-area .comment-respond input[type="month"]:focus,
.comments-area .comment-respond input[type="text"]:focus,
.comments-area .comment-respond input[type="email"]:focus,
.comments-area .comment-respond input[type="url"]:focus,
.comments-area .comment-respond input[type="password"]:focus,
.comments-area .comment-respond input[type="search"]:focus,
.comments-area .comment-respond input[type="tel"]:focus,
.comments-area .comment-respond input[type="number"]:focus,
.comments-area .comment-respond textarea:focus {
  border-color: #ff0008;
}
.comments-area .comment-respond .comment-form-author {
  float: left;
  width: 50%;
  padding-right: 10px;
  margin-bottom: 20px;
}
.comments-area .comment-respond .comment-form-email {
  float: left;
  width: 50%;
  padding-left: 12px;
  margin-bottom: 20px;
}
.comments-area .comment-respond .comment-form-url {
  float: left;
  width: 100%;
  margin-bottom: 20px;
}
.comments-area .comment-respond .comment-form-cookies-consent {
  width: 100%;
  float: left;
  position: relative;
  padding-left: 20px;
  margin-bottom: 20px;
}
.comments-area .comment-respond .comment-form-cookies-consent input {
  position: absolute;
  left: 0;
  top: 6px;
}
.comments-area .comment-respond .comment-form-cookies-consent label {
  display: inline-block;
  margin: 0;
  color: #6084a4;
  font-weight: 400;
}
.comments-area .comment-respond .form-submit {
  float: left;
  width: 100%;
}
.comments-area .comment-respond .form-submit input {
  background-color: #ff0008;
  border: none;
  color: #fff;
  padding: 11px 25px 10px;
  display: inline-block;
  cursor: pointer;
  outline: 0;
  border-radius: 0;
  text-transform: uppercase;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  /* font-family: raleway, sans-serif; */
  font-family: Montserrat !important;
  font-weight: 700;
  font-size: 14px;
}
.comments-area .comment-respond .form-submit input:hover,
.comments-area .comment-respond .form-submit input:focus {
  color: #fff;
  background-color: #44ce6f;
}
.page-title-area {
  position: relative;
  z-index: 1;
  background-color: #0e314c;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 200px;
  padding-bottom: 120px;
}
.page-title-area::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: -1;
  opacity: 0.6;
}
.page-title-area::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.04;
  height: 100%;
  z-index: -1;
  background-image: url(../img/bg_lines.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 200%;
}
.page-title-area.item-bg1 {
  background-image: url(../img/ICONB.png);
}
.page-title-area.item-bg2 {
  background-image: url(../img/ICONB.png);
}
.page-title-content {
  text-align: center;
}
.page-title-content h2 {
  color: #fff;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
  font-size: 40px;
  font-weight: 700;
}
.page-title-content p {
  color: #fff;
  max-width: 600px;
  /* font-family: raleway, sans-serif; */
  font-family: Montserrat !important;
  margin-top: 10px;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
}
.page-title-section {
  background: transparent url(../img/page-title-shape.jpg) right top no-repeat;
  padding-top: 200px;
  padding-bottom: 120px;
}
.page-title-text {
  position: relative;
}
.page-title-text h2 {
  max-width: 700px;
  margin-bottom: 0;
  font-size: 40px;
  font-weight: 700;
}
.page-title-text p {
  max-width: 600px;
  /* font-family: raleway, sans-serif; */
  font-family: Montserrat !important;
  margin-top: 8px;
  margin-bottom: 0;
}
.page-title-text ul {
  padding-left: 0;
  list-style-type: none;
  /* font-family: raleway, sans-serif; */
  font-family: Montserrat !important;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin-bottom: 0;
}
.page-title-text ul li {
  display: inline-block;
  color: #ff0008;
  margin-right: 10px;
  margin-left: 10px;
  position: relative;
  font-weight: 500;
  font-size: 15px;
}
.page-title-text ul li a {
  color: #0e314c;
}
.page-title-text ul li a:hover {
  color: #44ce6f;
}
.page-title-text ul li::before {
  content: "\f054";
  position: absolute;
  right: -15px;
  top: 5px;
  color: #0e314c;
  font-weight: 900;
  /* font-family: "font awesome 5 free"; */
  font-family: Montserrat !important;
  font-size: 10px;
}
.page-title-text ul li:last-child::before {
  display: none;
}
.page-title-text ul li:first-child {
  margin-left: 0;
}
.pagination-area {
  margin-top: 20px;
  text-align: center;
}
.pagination-area .page-numbers {
  width: 40px;
  height: 40px;
  margin: 0 3px;
  display: inline-block;
  background: #fff;
  line-height: 42px;
  color: #0e314c;
  -webkit-box-shadow: 0 2px 10px 0 #d8dde6;
  box-shadow: 0 2px 10px 0 #d8dde6;
  position: relative;
  z-index: 1;
  font-size: 16px;
  font-weight: 400;
}
.pagination-area .page-numbers::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ff0008),
    to(#ff6a00)
  );
  background: linear-gradient(90deg, #ff0008 0%, #ff6a00 100%);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.pagination-area .page-numbers.current,
.pagination-area .page-numbers:hover,
.pagination-area .page-numbers:focus {
  color: #fff;
}
.pagination-area .page-numbers.current::before,
.pagination-area .page-numbers:hover::before,
.pagination-area .page-numbers:focus::before {
  opacity: 1;
  visibility: visible;
}
.widget-area .widget {
  margin-top: 30px;
}
.widget-area .widget:first-child {
  margin-top: 0;
}
.widget-area .widget .widget-title {
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  margin-bottom: 20px;
  text-transform: capitalize;
  position: relative;
  font-weight: 600;
  font-size: 21px;
}
.widget-area .widget .widget-title::before {
  content: "";
  position: absolute;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ff0008),
    to(#ff6a00)
  );
  background: linear-gradient(90deg, #ff0008 0%, #ff6a00 100%);
  bottom: -1px;
  left: 0;
  width: 50px;
  height: 1px;
}
.widget-area .widget_search {
  -webkit-box-shadow: 0 0 29px 0 rgba(102, 102, 102, 0.1);
  box-shadow: 0 0 29px 0 rgba(102, 102, 102, 0.1);
  background-color: #fff;
  padding: 15px;
}
.widget-area .widget_search form {
  position: relative;
}
.widget-area .widget_search form label {
  display: block;
  margin-bottom: 0;
}
.widget-area .widget_search form .screen-reader-text {
  display: none;
}
.widget-area .widget_search form .search-field {
  background-color: transparent;
  height: 50px;
  padding: 6px 15px;
  border: 1px solid #eee;
  width: 100%;
  display: block;
  outline: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.widget-area .widget_search form .search-field:focus {
  border-color: #ff0008;
}
.widget-area .widget_search form button {
  position: absolute;
  right: 0;
  outline: 0;
  bottom: 0;
  height: 50px;
  width: 50px;
  z-index: 1;
  border: none;
  color: #fff;
  background-color: transparent;
}
.widget-area .widget_search form button::before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ff0008),
    to(#ff6a00)
  );
  background: linear-gradient(90deg, #ff0008 0%, #ff6a00 100%);
  top: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.widget-area .widget_search form button::after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: linear-gradient(135deg, #e7322f 0%, #ff0008 100%);
  top: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}
.widget-area .widget_search form button:hover::before {
  opacity: 0;
  visibility: hidden;
}
.widget-area .widget_search form button:hover::after {
  opacity: 1;
  visibility: visible;
}
.widget-area .widget_luvion_posts_thumb {
  position: relative;
  overflow: hidden;
}
.widget-area .widget_luvion_posts_thumb .item {
  overflow: hidden;
  margin-bottom: 15px;
}
.widget-area .widget_luvion_posts_thumb .item:last-child {
  margin-bottom: 0;
}
.widget-area .widget_luvion_posts_thumb .item .thumb {
  float: left;
  height: 80px;
  overflow: hidden;
  position: relative;
  width: 80px;
  margin-right: 15px;
}
.widget-area .widget_luvion_posts_thumb .item .thumb .fullimage {
  width: 80px;
  height: 80px;
  display: inline-block;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center center !important;
}
.widget-area .widget_luvion_posts_thumb .item .thumb .fullimage.bg1 {
  background-image: url(../img/blog-image/1.jpg);
}
.widget-area .widget_luvion_posts_thumb .item .thumb .fullimage.bg2 {
  background-image: url(../img/blog-image/2.jpg);
}
.widget-area .widget_luvion_posts_thumb .item .thumb .fullimage.bg3 {
  background-image: url(../img/blog-image/3.jpg);
}
.widget-area .widget_luvion_posts_thumb .item .info {
  overflow: hidden;
}
.widget-area .widget_luvion_posts_thumb .item .info time {
  display: block;
  color: #6084a4;
  text-transform: uppercase;
  margin-top: 5px;
  margin-bottom: 3px;
  font-size: 11px;
}
.widget-area .widget_luvion_posts_thumb .item .info .title {
  margin-bottom: 0;
  line-height: 1.5;
  font-size: 16px;
  font-weight: 600;
}
.widget-area .widget_recent_entries ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.widget-area .widget_recent_entries ul li {
  position: relative;
  margin-bottom: 12px;
  padding-left: 14px;
  line-height: 1.5;
  font-weight: 500;
  font-size: 14.5px;
}
.widget-area .widget_recent_entries ul li:last-child {
  margin-bottom: 0;
}
.widget-area .widget_recent_entries ul li::before {
  background: #ff0008;
  position: absolute;
  height: 7px;
  width: 7px;
  content: "";
  left: 0;
  top: 7px;
}
.widget-area .widget_recent_entries ul li .post-date {
  display: block;
  font-size: 13px;
  color: #6084a4;
  margin-top: 4px;
}
.widget-area .widget_recent_comments ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.widget-area .widget_recent_comments ul li {
  position: relative;
  margin-bottom: 12px;
  color: #0e314c;
  padding-left: 14px;
  line-height: 1.5;
  font-weight: 500;
  font-size: 14.5px;
}
.widget-area .widget_recent_comments ul li:last-child {
  margin-bottom: 0;
}
.widget-area .widget_recent_comments ul li::before {
  background: #ff0008;
  height: 7px;
  width: 7px;
  content: "";
  left: 0;
  top: 7px;
  position: absolute;
}
.widget-area .widget_recent_comments ul li span {
  display: inline-block;
}
.widget-area .widget_recent_comments ul li a {
  display: inline-block;
}
.widget-area .widget_archive ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.widget-area .widget_archive ul li {
  position: relative;
  margin-bottom: 12px;
  padding-left: 14px;
  font-weight: 500;
  font-size: 14.5px;
}
.widget-area .widget_archive ul li:last-child {
  margin-bottom: 0;
}
.widget-area .widget_archive ul li::before {
  background: #ff0008;
  height: 7px;
  width: 7px;
  content: "";
  left: 0;
  top: 7px;
  position: absolute;
}
.widget-area .widget_categories ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.widget-area .widget_categories ul li {
  position: relative;
  margin-bottom: 12px;
  padding-left: 14px;
  font-weight: 500;
  font-size: 14.5px;
}
.widget-area .widget_categories ul li:last-child {
  margin-bottom: 0;
}
.widget-area .widget_categories ul li::before {
  background: #ff0008;
  height: 7px;
  width: 7px;
  content: "";
  left: 0;
  top: 7px;
  position: absolute;
}
.widget-area .widget_categories ul li .post-count {
  float: right;
}
.widget-area .widget_meta ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.widget-area .widget_meta ul li {
  position: relative;
  margin-bottom: 12px;
  padding-left: 14px;
  font-weight: 500;
  font-size: 14.5px;
}
.widget-area .widget_meta ul li:last-child {
  margin-bottom: 0;
}
.widget-area .widget_meta ul li::before {
  background: #ff0008;
  height: 7px;
  width: 7px;
  content: "";
  left: 0;
  top: 7px;
  position: absolute;
}
.widget-area .widget_tag_cloud .widget-title {
  margin-bottom: 12px;
}
.widget-area .tagcloud a {
  display: inline-block;
  padding: 6px 13px;
  border: 1px dashed #eee;
  position: relative;
  font-weight: 600;
  font-size: 13.5px !important;
  margin-top: 8px;
  margin-right: 4px;
}
.widget-area .tagcloud a::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ff0008),
    to(#ff6a00)
  );
  background: linear-gradient(90deg, #ff0008 0%, #ff6a00 100%);
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
}
.widget-area .tagcloud a:hover,
.widget-area .tagcloud a:focus {
  color: #fff;
  border-color: #ff0008;
}
.widget-area .tagcloud a:hover::before,
.widget-area .tagcloud a:focus::before {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.error-area {
  height: 100vh;
}
.error-content {
  text-align: center;
  margin: 0 auto;
  max-width: 700px;
}
.error-content h3 {
  font-size: 40px;
  font-weight: 700;
  margin-top: 35px;
  margin-bottom: 15px;
}
.error-content p {
  max-width: 520px;
  margin: 0 auto 20px;
}
.faq-content h2 {
  margin-bottom: 0;
  font-size: 40px;
  font-weight: 600;
}
.faq-content .bar {
  height: 5px;
  width: 90px;
  background: #cdf1d8;
  margin: 20px 0 25px;
  position: relative;
  border-radius: 30px;
}
.faq-content .bar::before {
  content: "";
  position: absolute;
  left: 0;
  top: -2.7px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: #44ce6f;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: MOVE-BG;
  animation-name: MOVE-BG;
}
.faq-content .faq-image {
  margin-top: 20px;
}
.faq-accordion .accordion {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}
.faq-accordion .accordion .accordion-item {
  display: block;
  -webkit-box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  background: #fff;
  margin-bottom: 10px;
}
.faq-accordion .accordion .accordion-item:last-child {
  margin-bottom: 0;
}
.faq-accordion .accordion .accordion-title {
  padding: 12px 20px 12px 51px;
  color: #0e314c;
  position: relative;
  border-bottom: 1px solid transparent;
  margin-bottom: -1px;
  display: block;
  font-size: 15px;
  font-weight: 600;
  /* font-family: raleway, sans-serif; */
  font-family: Montserrat !important;
}
.faq-accordion .accordion .accordion-title i {
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  text-align: center;
  height: 100%;
  background: #ff0008;
  color: #fff;
}
.faq-accordion .accordion .accordion-title i::before {
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 13px;
}
.faq-accordion .accordion .accordion-title.active {
  border-bottom-color: #eee;
}
.faq-accordion .accordion .accordion-title.active i::before {
  content: "\f068";
}
.faq-accordion .accordion .accordion-content {
  display: none;
  position: relative;
  padding: 15px;
  font-size: 14.5px;
}
.faq-accordion .accordion .accordion-content.show {
  display: block;
}
.faq-contact {
  margin-top: 70px;
}
.faq-contact-form {
  max-width: 750px;
  margin: 0 auto;
  text-align: center;
}
.faq-contact-form form .form-group {
  margin-bottom: 15px;
}
.faq-contact-form form .form-control2 {
  background-color: #fff;
  border: none;
  -webkit-box-shadow: 0 5px 28.5px 1.5px rgba(149, 152, 200, 0.2) !important;
  box-shadow: 0 5px 28.5px 1.5px rgba(149, 152, 200, 0.2) !important;
  height: 50px;
  /* font-family: raleway, sans-serif; */
  font-family: Montserrat !important;
  font-size: 14.5px;
}
.faq-contact-form form .row {
  margin-left: -7px;
  margin-right: -7px;
}
.faq-contact-form form .row .col-lg-12,
.faq-contact-form form .row .col-lg-6 {
  padding-left: 7px;
  padding-right: 7px;
}
.faq-contact-form form textarea.form-control2 {
  height: auto;
  padding-top: 15px;
  line-height: initial;
}
.faq-contact-form form .btn {
  margin-top: 8px;
  -webkit-box-shadow: 0 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
  box-shadow: 0 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
}
.faq-contact-form form .help-block ul {
  padding-left: 0;
  list-style-type: none;
  text-align: left;
  margin-top: 5px;
  margin-bottom: 0;
}
.faq-contact-form form .help-block ul li {
  color: red;
  font-weight: 300;
}
.faq-contact-form form #msgSubmit {
  margin-bottom: 0;
  text-align: center !important;
}
.faq-contact-form form #msgSubmit.text-danger,
.faq-contact-form form #msgSubmit.text-success {
  margin-top: 20px;
  font-size: 22px;
  font-weight: 300;
}
.login-image {
  height: 100%;
  width: 100%;
  background-image: url(../img/login-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.login-image img {
  display: none;
}
.login-content {
  height: 100vh;
}
.login-content .login-form {
  text-align: center;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}
.login-content .login-form .logo {
  margin-bottom: 35px;
}
.login-content .login-form .logo a {
  display: inline-block;
}
.login-content .login-form h3 {
  margin-bottom: 0;
  font-size: 30px;
  font-weight: 700;
}
.login-content .login-form p {
  margin-top: 7px;
  margin-bottom: 0;
}
.login-content .login-form form {
  margin-top: 35px;
}
.login-content .login-form form .form-group {
  margin-bottom: 15px;
}
.login-content .login-form form .form-control2 {
  background-color: #fff;
  color: #0e314c;
  border: none;
  -webkit-box-shadow: 0 5px 28.5px 1.5px rgba(149, 152, 200, 0.2) !important;
  box-shadow: 0 5px 28.5px 1.5px rgba(149, 152, 200, 0.2) !important;
  height: 50px;
  /* font-family: raleway, sans-serif; */
  font-family: Montserrat !important;
  font-size: 14px;
}
.login-content .login-form form .btn {
  display: block;
  width: 100%;
  -webkit-box-shadow: 0 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
  box-shadow: 0 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
}
.login-content .login-form form .forgot-password {
  text-align: right;
  margin-top: 15px;
}
.login-content .login-form form .forgot-password a {
  display: inline-block;
  color: #ff0008;
  text-decoration: underline;
}
.login-content .login-form form .connect-with-social {
  margin-top: 15px;
}
.login-content .login-form form .connect-with-social button {
  display: block;
  width: 100%;
  position: relative;
  border: 1px solid #ff0008;
  background-color: transparent;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  padding: 11px 30px;
  border-radius: 2px;
  color: #ff0008;
  /* font-family: raleway, sans-serif; */
  font-family: Montserrat !important;
  font-weight: 500;
}
.login-content .login-form form .connect-with-social button i {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 15px;
  font-size: 20px;
}
.login-content .login-form form .connect-with-social button.facebook {
  border-color: #3b5998;
  color: #3b5998;
}
.login-content .login-form form .connect-with-social button.facebook:hover {
  background-color: #3b5998;
  color: #fff;
  border-color: #3b5998;
}
.login-content .login-form form .connect-with-social button.google {
  margin-top: 10px;
  border-color: #ea4335;
  color: #ea4335;
}
.login-content .login-form form .connect-with-social button.google:hover {
  background-color: #ea4335;
  color: #fff;
  border-color: #ea4335;
}
.login-content .login-form form .connect-with-social button:hover {
  background-color: #ff0008;
  color: #fff;
  border-color: #ff0008;
}
.signup-image {
  height: 100%;
  width: 100%;
  background-image: url(../img/signup-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.signup-image img {
  display: none;
}
.signup-content {
  height: 100vh;
}
.signup-content .signup-form {
  text-align: center;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}
.signup-content .signup-form .logo {
  margin-bottom: 35px;
}
.signup-content .signup-form .logo a {
  display: inline-block;
}
.signup-content .signup-form h3 {
  margin-bottom: 0;
  font-size: 30px;
  font-weight: 700;
}
.signup-content .signup-form p {
  margin-top: 7px;
  margin-bottom: 0;
}
.signup-content .signup-form form {
  margin-top: 35px;
}
.signup-content .signup-form form .form-group {
  margin-bottom: 15px;
}
.signup-content .signup-form form .form-control2 {
  background-color: #fff;
  color: #0e314c;
  border: none;
  -webkit-box-shadow: 0 5px 28.5px 1.5px rgba(149, 152, 200, 0.2) !important;
  box-shadow: 0 5px 28.5px 1.5px rgba(149, 152, 200, 0.2) !important;
  height: 50px;
  /* font-family: raleway, sans-serif; */
  font-family: Montserrat !important;
  font-size: 14px;
}
.signup-content .signup-form form .btn {
  display: block;
  width: 100%;
  -webkit-box-shadow: 0 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
  box-shadow: 0 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
}
.signup-content .signup-form form .connect-with-social {
  margin-top: 20px;
}
.signup-content .signup-form form .connect-with-social span {
  display: block;
  text-transform: uppercase;
  color: #6084a4;
  margin-bottom: 20px;
}
.signup-content .signup-form form .connect-with-social button {
  display: block;
  width: 100%;
  position: relative;
  border: 1px solid #ff0008;
  background-color: transparent;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  padding: 11px 30px;
  border-radius: 2px;
  color: #ff0008;
  /* font-family: raleway, sans-serif; */
  font-family: Montserrat !important;
  font-weight: 500;
}
.signup-content .signup-form form .connect-with-social button i {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 15px;
  font-size: 20px;
}
.signup-content .signup-form form .connect-with-social button.facebook {
  border-color: #3b5998;
  color: #3b5998;
}
.signup-content .signup-form form .connect-with-social button.facebook:hover {
  background-color: #3b5998;
  color: #fff;
  border-color: #3b5998;
}
.signup-content .signup-form form .connect-with-social button.google {
  margin-top: 10px;
  border-color: #ea4335;
  color: #ea4335;
}
.signup-content .signup-form form .connect-with-social button.google:hover {
  background-color: #ea4335;
  color: #fff;
  border-color: #ea4335;
}
.signup-content .signup-form form .connect-with-social button:hover {
  background-color: #ff0008;
  color: #fff;
  border-color: #ff0008;
}
.contact-area {
  position: relative;
  z-index: 1;
}
.contact-form form .form-group {
  margin-bottom: 20px;
}
.contact-form form .form-control2 {
  background-color: #fff;
  border: none;
  -webkit-box-shadow: 0 5px 28.5px 1.5px rgba(149, 152, 200, 0.2) !important;
  box-shadow: 0 5px 28.5px 1.5px rgba(149, 152, 200, 0.2) !important;
  height: 50px;
  /* font-family: raleway, sans-serif; */
  font-family: Montserrat !important;
  font-size: 14.5px;
}
.contact-form form .row {
  margin-left: -7px;
  margin-right: -7px;
}
.contact-form form .row .col-lg-12,
.contact-form form .row .col-lg-6 {
  padding-left: 7px;
  padding-right: 7px;
}
.contact-form form textarea.form-control2 {
  height: auto;
  padding-top: 15px;
  line-height: initial;
}
.contact-form form .btn {
  margin-top: 8px;
  -webkit-box-shadow: 0 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
  box-shadow: 0 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
}
.contact-form form .help-block ul {
  padding-left: 0;
  list-style-type: none;
  margin-top: 5px;
  margin-bottom: 0;
}
.contact-form form .help-block ul li {
  color: red;
  font-weight: 300;
}
.contact-form form #msgSubmit {
  margin-bottom: 0;
  text-align: left !important;
}
.contact-form form #msgSubmit.text-danger,
.contact-form form #msgSubmit.text-success {
  margin-top: 8px;
  font-size: 22px;
  font-weight: 300;
}
.contact-info {
  padding-right: 25px;
}
.contact-info ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.contact-info ul li {
  position: relative;
  padding-left: 95px;
  color: #6084a4;
  margin-bottom: 35px;
  font-weight: 300;
  line-height: 1.7;
}
.contact-info ul li .icon {
  border: 1px dashed #ff0008;
  width: 75px;
  height: 75px;
  line-height: 75px;
  color: #fff;
  border-radius: 50%;
  font-size: 25px;
  text-align: center;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.contact-info ul li .icon::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ff0008),
    to(#ff6a00)
  );
  background: linear-gradient(90deg, #ff0008 0%, #ff6a00 100%);
  border-radius: 50%;
  margin: 5px;
  z-index: -1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.contact-info ul li .icon::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(135deg, #e7322f 0%, #ff0008 100%);
  border-radius: 50%;
  margin: 5px;
  z-index: -1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}
.contact-info ul li span {
  display: block;
  margin-bottom: 3px;
  color: #0e314c;
  font-size: 22px;
  font-weight: 600;
  /* font-family: raleway, sans-serif; */
  font-family: Montserrat !important;
}
.contact-info ul li a {
  display: block;
  color: #6084a4;
}
.contact-info ul li a:hover {
  color: #ff0008;
}
.contact-info ul li:hover .icon {
  border-color: #44ce6f;
}
.contact-info ul li:hover .icon::before {
  opacity: 0;
  visibility: hidden;
}
.contact-info ul li:hover .icon::after {
  opacity: 1;
  visibility: visible;
}
.contact-info ul li:last-child {
  margin-bottom: 0;
}
.bg-map {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: -1;
  text-align: center;
  margin: 0 auto;
  opacity: 0.5;
}
.footer-area {
  position: relative;
  z-index: 1;
  padding-top: 70px;
  background-color: #f7fafd;
}
.single-footer-widget {
  margin-bottom: 30px;
}
.single-footer-widget .logo a {
  display: block;
}
.single-footer-widget .logo p {
  font-size: 14.5px;
  margin-top: 20px;
  margin-bottom: 0;
}
.single-footer-widget h3 {
  margin-bottom: 24px;
  font-size: 22px;
  font-weight: 600;
}
.single-footer-widget .list {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.single-footer-widget .list li {
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 14.5px;
}
.single-footer-widget .list li a {
  color: #6084a4;
  display: inline-block;
}
.single-footer-widget .list li a:hover {
  padding-left: 5px;
  color: #ff0008;
}
.single-footer-widget .list li:last-child {
  margin-bottom: 0;
}
.single-footer-widget .footer-contact-info {
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
}
.single-footer-widget .footer-contact-info li {
  position: relative;
  color: #6084a4;
  margin-bottom: 8px;
  line-height: 1.7;
  font-weight: 400;
  font-size: 14.5px;
}
.single-footer-widget .footer-contact-info li a {
  color: #6084a4;
  display: inline-block;
}
.single-footer-widget .footer-contact-info li a:hover {
  color: #ff0008;
}
.single-footer-widget .footer-contact-info li:last-child {
  margin-bottom: 0;
}
.single-footer-widget .footer-contact-info li span {
  display: inline-block;
  font-weight: 500;
}
.single-footer-widget .social-links {
  padding-left: 0;
  list-style-type: none;
  margin-top: 13px;
  margin-bottom: 0;
}
.single-footer-widget .social-links li {
  margin-right: 2px;
  display: inline-block;
}
.single-footer-widget .social-links li a {
  width: 30px;
  text-align: center;
  line-height: 29px;
  height: 30px;
  border: 1px solid #ece9e9;
  border-radius: 50%;
  color: #ff0008;
  display: inline-block;
  font-size: 14px;
}
.single-footer-widget .social-links li a:hover {
  color: #fff;
  border-color: #ff0008;
  background-color: #ff0008;
}
.copyright-area {
  border-top: 1px solid #d8ebfd;
  text-align: center;
  margin-top: 40px;
  padding-top: 25px;
  padding-bottom: 25px;
}
.copyright-area p {
  font-size: 14.5px;
}
.copyright-area p a {
  display: inline-block;
  color: #0e314c;
  font-weight: 400;
}
.copyright-area p a:hover {
  color: #ff0008;
}
.map-image {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 0 auto;
  text-align: center;
  z-index: -1;
  opacity: 0.7;
}
.map-image img {
  max-width: 40%;
}
.go-top {
  position: fixed;
  cursor: pointer;
  top: 50%;
  right: 15px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ff0008),
    to(#ff6a00)
  );
  background: linear-gradient(90deg, #ff0008 0%, #ff6a00 100%);
  z-index: 4;
  width: 40px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.9s;
  transition: 0.9s;
  color: #fff;
}
.go-top.active {
  top: 98%;
  -webkit-transform: translateY(-98%);
  transform: translateY(-98%);
  opacity: 1;
  visibility: visible;
}
.go-top::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: linear-gradient(135deg, #e7322f 0%, #ff0008 100%);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.go-top:hover,
.go-top:focus {
  color: #fff;
}
.go-top:hover::before,
.go-top:focus::before {
  opacity: 1;
  visibility: visible;
}
.banner-wrapper {
  position: relative;
  z-index: 1;
  padding-top: 90px;
}
.banner-wrapper::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: linear-gradient(151.59deg, #ff2f2f 10.43%, #000460 92.78%),
    radial-gradient(100% 246.94% at 100% 0, #fff 0, #020063 100%),
    linear-gradient(143.18deg, #1400ff 0.45%, #3a0000),
    linear-gradient(167.55deg, #ff002e, #ff003d 74.04%),
    linear-gradient(351.18deg, #b25bba 7.52%, #1700a7 77.98%),
    linear-gradient(202.44deg, #a90000 12.99%, #00ffe0 87.21%),
    linear-gradient(165.77deg, #b7d500 15.35%, #20a 89.57%);
  background-blend-mode: overlay, color-burn, screen, overlay, difference,
    difference, normal;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 70%, 0% 100%);
  clip-path: polygon(0 0, 100% 0, 100% 70%, 0% 100%);
}
.banner-wrapper .container-fluid {
  padding-left: 0;
  padding-right: 0;
}
.banner-wrapper .container-fluid .row {
  margin-left: 0;
  margin-right: 0;
}
.banner-wrapper .container-fluid .row .col-lg-6 {
  padding-left: 0;
  padding-right: 0;
}
.banner-wrapper-content {
  max-width: 640px;
  padding-right: 20px;
  margin-left: auto;
  margin-top: -50px;
}
.banner-wrapper-content h1 {
  margin-bottom: 0;
  color: #fff;
  font-size: 50px;
  font-weight: 700;
}
.banner-wrapper-content p {
  color: #fff;
  /* font-family: raleway, sans-serif; */
  font-family: Montserrat !important;
  font-size: 18px;
  max-width: 400px;
  margin-top: 20px;
  margin-bottom: 0;
}
.banner-wrapper-content .btn {
  margin-top: 30px;
}
.banner-wrapper-image {
  text-align: right;
}
.single-partner-item {
  text-align: center;
}
.single-partner-item img {
  display: inline-block !important;
  width: auto !important;
}
.payment-experience-content h2 {
  margin-bottom: 18px;
  font-size: 40px;
  font-weight: 600;
}
.payment-experience-content .link-btn {
  display: inline-block;
  font-weight: 600;
  color: #ff0008;
  position: relative;
  margin-top: 5px;
  line-height: 1;
}
.payment-experience-content .link-btn::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ff0008),
    to(#ff6a00)
  );
  background: linear-gradient(90deg, #ff0008 0%, #ff6a00 100%);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.payment-experience-content .link-btn:hover::before {
  width: 0;
}
.single-payment-experience-box {
  margin-top: 20px;
}
.single-payment-experience-box .icon {
  margin-bottom: 22px;
  width: 90px;
  height: 90px;
  text-align: center;
  line-height: 97px;
  background-color: #f3c7db;
  border-radius: 50%;
  position: relative;
  color: #fff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 40px;
  padding-right: 5px;
}
.single-payment-experience-box .icon i {
  position: relative;
  z-index: 1;
}
.single-payment-experience-box .icon::before {
  content: "";
  -webkit-transition: 0.5s;
  transition: 0.5s;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #f281ac;
  border-radius: 50%;
  margin-top: 5px;
  margin-right: 5px;
}
.single-payment-experience-box h3 {
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: 600;
}
.single-payment-experience-box:hover .icon {
  background-color: #0e314c;
}
.single-payment-experience-box:hover .icon::before {
  background-color: #ff0008;
}
.col-lg-3:nth-child(2) .single-payment-experience-box .icon {
  background-color: #c1e9c5;
}
.col-lg-3:nth-child(2) .single-payment-experience-box .icon::before {
  background-color: #7dd179;
}
.col-lg-3:nth-child(2) .single-payment-experience-box:hover .icon {
  background-color: #0e314c;
}
.col-lg-3:nth-child(2) .single-payment-experience-box:hover .icon::before {
  background-color: #ff0008;
}
.col-lg-3:nth-child(3) .single-payment-experience-box .icon {
  background-color: #bdc7d4;
}
.col-lg-3:nth-child(3) .single-payment-experience-box .icon::before {
  background-color: #73819c;
}
.col-lg-3:nth-child(3) .single-payment-experience-box:hover .icon {
  background-color: #0e314c;
}
.col-lg-3:nth-child(3) .single-payment-experience-box:hover .icon::before {
  background-color: #ff0008;
}
.col-lg-3:nth-child(4) .single-payment-experience-box .icon {
  background-color: #bce5e5;
}
.col-lg-3:nth-child(4) .single-payment-experience-box .icon::before {
  background-color: #72c7c2;
}
.col-lg-3:nth-child(4) .single-payment-experience-box:hover .icon {
  background-color: #0e314c;
}
.col-lg-3:nth-child(4) .single-payment-experience-box:hover .icon::before {
  background-color: #ff0008;
}
.support-image {
  position: relative;
  padding-right: 130px;
  text-align: left;
}
.support-image img:nth-child(2) {
  position: absolute;
  right: 50px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.support-content h2 {
  margin-bottom: 20px;
  font-size: 40px;
  font-weight: 600;
}
.support-content .btn {
  margin-top: 10px;
}
.business-area {
  background-color: #051947;
}
.business-content h2 {
  color: #fff;
  margin-bottom: 0;
  font-size: 40px;
  font-weight: 600;
}
.business-content .single-business-box {
  margin-top: 35px;
}
.business-content .single-business-box h3 {
  color: #fff;
  margin-bottom: 12px;
  font-size: 22px;
  font-weight: 600;
}
.business-content .single-business-box p {
  color: #fff;
  opacity: 0.9;
}
.business-image {
  margin-left: 50px;
  text-align: center;
  background: linear-gradient(125deg, #fdff9c 0%, #0500ff 100%),
    linear-gradient(180deg, #d3d3d3 0%, #161616 100%),
    linear-gradient(
      310deg,
      #00f0ff 0%,
      #00f0ff 20%,
      #0017e3 calc(20% + 1px),
      #0017e3 40%,
      #000f8f calc(40% + 1px),
      #000f8f 70%,
      #00073f calc(70% + 1px),
      #00073f 100%
    ),
    linear-gradient(
      285deg,
      #ffb6b9 0%,
      #ffb6b9 35%,
      #fae3d9 calc(35% + 1px),
      #fae3d9 45%,
      #bbded6 calc(45% + 1px),
      #bbded6 65%,
      #61c0bf calc(65% + 1px),
      #61c0bf 100%
    );
  background-blend-mode: overlay, overlay, exclusion, normal;
  padding: 50px;
}
.testimonials-area {
  padding-bottom: 40px;
}
.single-testimonials-box {
  -webkit-box-shadow: 5px 5px #8b98b5;
  box-shadow: 5px 5px #8b98b5;
  margin-bottom: 30px;
  padding: 30px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border: 1px solid #8b98b5;
}
.single-testimonials-box .rating {
  margin-bottom: 15px;
}
.single-testimonials-box .rating i {
  font-size: 15px;
  color: #ffc107;
}
.single-testimonials-box p {
  margin: 0;
  color: #374d7f;
  font-size: 15px;
  font-weight: 500;
}
.single-testimonials-box h3 {
  color: #0e314c;
  margin-top: 20px;
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 700;
}
.single-testimonials-box h3 span {
  font-weight: 400;
  color: #6084a4;
  display: inline-block;
  margin-left: 5px;
}
.single-testimonials-box:hover {
  -webkit-box-shadow: 5px 5px #0e314c;
  box-shadow: 5px 5px #0e314c;
}
.global-area {
  background-color: #051947;
}
.global-area .section-title h2 {
  color: #fff;
}
.global-area .section-title p {
  color: #fff;
}
.global-content {
  padding-right: 30px;
}
.global-content ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.global-content ul li {
  margin-bottom: 12px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.12);
  padding: 30px 40px 28px;
  position: relative;
  border-radius: 2px;
  z-index: 1;
  font-size: 20px;
  font-weight: 600;
}
.global-content ul li:last-child {
  margin-bottom: 0;
}
.global-content ul li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0%;
  border-radius: 2px;
  height: 100%;
  z-index: -1;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ff0008),
    to(#ff6a00)
  );
  background: linear-gradient(90deg, #ff0008 0%, #ff6a00 100%);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.global-content ul li:hover::before {
  width: 100%;
}
.success-story-inner {
  background-color: #051947;
  position: relative;
  z-index: 1;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 90px;
  padding-right: 135px;
}
.success-story-inner::before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 30%;
  z-index: -1;
  background: linear-gradient(
      125deg,
      #00ff57 0%,
      #010033 40%,
      #460043 70%,
      #f0ffc5 100%
    ),
    linear-gradient(55deg, #0014c9 0%, #410060 100%),
    linear-gradient(300deg, #ffc700 0%, #001aff 100%),
    radial-gradient(
      135% 215% at 115% 40%,
      #393939 0%,
      #393939 40%,
      #849561 calc(40% + 1px),
      #849561 60%,
      #eed690 calc(60% + 1px),
      #eed690 80%,
      #ecefd8 calc(80% + 1px),
      #ecefd8 100%
    ),
    linear-gradient(
      125deg,
      #282d4f 0%,
      #282d4f 40%,
      #23103a calc(40% + 1px),
      #23103a 70%,
      #a0204c calc(70% + 1px),
      #a0204c 88%,
      #ff6c00 calc(88% + 1px),
      #ff6c00 100%
    );
  background-blend-mode: overlay, screen, overlay, overlay, normal;
}
.success-story-inner .owl-theme .owl-nav {
  margin-top: 0;
  position: absolute;
  right: -90px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.success-story-inner .owl-theme .owl-nav [class*="owl-"] {
  display: block;
  width: 45px;
  height: 45px;
  line-height: 48px;
  background-color: #fff;
  border-radius: 50%;
  margin: 10px 0;
  font-size: 18px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.success-story-inner .owl-theme .owl-nav [class*="owl-"]:hover {
  background-color: #ff0008;
  color: #fff;
}
.single-success-story-box .content {
  padding-right: 40px;
}
.single-success-story-box .content p {
  margin: 0;
  color: #fff;
  font-size: 22px;
  font-weight: 600;
}
.single-success-story-box .content h3 {
  color: #e8e8e8;
  margin-top: 25px;
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 700;
}
.single-success-story-box .content h3 span {
  font-weight: 300;
  color: #e7e7e7;
  display: inline-block;
  margin-left: 5px;
}
.single-success-story-box .content .btn {
  margin-top: 30px;
}
.payment-features-area {
  position: relative;
  z-index: 1;
}
.payment-features-area::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  background-color: #f4fcff;
  -webkit-clip-path: polygon(0 63%, 100% 30%, 100% 100%, 0% 100%);
  clip-path: polygon(0 63%, 100% 30%, 100% 100%, 0% 100%);
}
.payment-features-overview {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  margin-top: 70px;
}
.payment-features-overview .payment-features-content {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 15px;
  padding-right: 15px;
}
.payment-features-overview .payment-features-content .content {
  padding-left: 15px;
}
.payment-features-overview .payment-features-content .content h2 {
  margin-bottom: 18px;
  font-size: 40px;
  font-weight: 600;
}
.payment-features-overview .payment-features-content .content .link-btn {
  display: inline-block;
  font-weight: 600;
  color: #ff0008;
  position: relative;
  margin-top: 5px;
  line-height: 1;
}
.payment-features-overview
  .payment-features-content
  .content
  .link-btn::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ff0008),
    to(#ff6a00)
  );
  background: linear-gradient(90deg, #ff0008 0%, #ff6a00 100%);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.payment-features-overview
  .payment-features-content
  .content
  .link-btn:hover::before {
  width: 0;
}
.payment-features-overview .payment-features-image {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 15px;
  padding-right: 15px;
}
.payment-features-overview .payment-features-image .image {
  margin-right: 15px;
  text-align: center;
  background: linear-gradient(45deg, #000850 0%, #000320 100%),
    radial-gradient(100% 225% at 100% 0%, #ff6928 0%, #000000 100%),
    linear-gradient(225deg, #ff7a00 0%, #000000 100%),
    linear-gradient(
      135deg,
      #cdffeb 10%,
      #cdffeb 35%,
      #009f9d 35%,
      #009f9d 60%,
      #07456f 60%,
      #07456f 67%,
      #0f0a3c 67%,
      #0f0a3c 100%
    );
  background-blend-mode: screen, overlay, hard-light, normal;
  padding: 50px;
}
.payment-features-overview:first-child {
  margin-top: 0;
}
.payment-features-overview:nth-child(2) .payment-features-content .content,
.payment-features-overview:nth-child(4) .payment-features-content .content,
.payment-features-overview:nth-child(6) .payment-features-content .content {
  padding-left: 0;
  padding-right: 15px;
}
.payment-features-overview:nth-child(2) .payment-features-image .image,
.payment-features-overview:nth-child(4) .payment-features-image .image,
.payment-features-overview:nth-child(6) .payment-features-image .image {
  background: linear-gradient(123deg, #ffffff 0%, #00b2ff 100%),
    linear-gradient(236deg, #baff99 0%, #005e64 100%),
    linear-gradient(180deg, #ffffff 0%, #002a5a 100%),
    linear-gradient(
      225deg,
      #0094ff 20%,
      #bff4ed 45%,
      #280f34 45%,
      #280f34 70%,
      #ff004e 70%,
      #e41655 85%,
      #b30753 85%,
      #b30753 100%
    ),
    linear-gradient(
      135deg,
      #0e0220 15%,
      #0e0220 35%,
      #e40475 35%,
      #e40475 60%,
      #48e0e4 60%,
      #48e0e4 68%,
      #d7fbf6 68%,
      #d7fbf6 100%
    );
  background-blend-mode: overlay, overlay, overlay, darken, normal;
  margin-right: 0;
  margin-left: 15px;
}
.get-started-area {
  background: linear-gradient(129.96deg, #ff2f2f 10.43%, #000460 92.78%),
    radial-gradient(100% 246.94% at 100% 0%, #ffffff 0%, #020063 100%),
    linear-gradient(58.72deg, #2200f2 0%, #530000 100%),
    linear-gradient(154.03deg, #b70000 0%, #ff003d 74.04%),
    linear-gradient(341.1deg, #ff0000 7.52%, #0038ff 77.98%),
    linear-gradient(136.23deg, #00c2ff 11.12%, #ff0000 86.47%),
    radial-gradient(57.37% 100% at 50% 0%, #b50000 0%, #0034bb 100%);
  background-blend-mode: overlay, color-burn, screen, overlay, difference,
    difference, normal;
}
.get-started-title h2 {
  color: #fff;
  margin-bottom: 30px;
  font-size: 40px;
  font-weight: 600;
}
.get-started-content p {
  color: #e9fffe;
  font-size: 20px;
  font-weight: 500;
}
/* Login Page Reponsiveness is handled below */
.tophead {
  text-align: center !important;
  margin-top: -82px;
  width: 100% !important;
}

@media only screen and (max-width: 600px) {
  .tophead {
    text-align: center !important;
    margin-top: 8px;
    width: 100% !important;
  }
}

.topside {
  text-align: left !important;
  width: 50% !important;
}

@media only screen and (max-width: 600px) {
  .topside {
    text-align: center !important;
    width: 100% !important;
  }
}

/* Home page reponsiveness handled below */

@media only screen and (max-width: 800px) {
  .superhead {
    visibility: hidden;
  }
}
