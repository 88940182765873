.paginate_button {
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  border: 1px solid #dee2e6;
  border-collapse: collapse;
}

.paginate_button.current {
  color: #ffffff;
  background-color: #d92550;
  border-color: #d92550;
}

.dataTables_paginate {
  margin-top: -24px;
}
